import { ListItem, Pill, TaskItemIcon, TaskItemIconProps } from '@cutover/react-ui'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { StreamModel, TaskTypeModel } from 'main/data-access'
import { useTaskListTask } from 'main/recoil/data-access'
import { StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'

export const TaskLineItem = ({
  task,
  stream,
  taskType,
  onClick,
  onClickRemove,
  active,
  role,
  disabled,
  errors = []
}: {
  errors?: string[]
  task: TaskListTask
  stream: StreamListStream
  taskType: TaskType
  onClick?: () => void
  onClickRemove?: () => void
  active?: boolean
  role?: string
  disabled?: boolean
}) => {
  const { name, internal_id } = task

  const iconProps = {
    color: stream.color,
    icon: taskTypeIcon(taskType.icon, task.stage),
    inProgress: task.stage === 'in-progress',
    isOpaque: task.stage === 'complete',
    stageIcon: stageIconName({
      completionType: task.completion_type,
      stage: task.stage,
      startFixed: task.start_fixed
    })
  }

  return taskType && stream ? (
    <ListItem
      hasError={errors.length > 0}
      tabIndex={undefined}
      size="small"
      startComponent={<TaskItemIcon iconSize="xsmall" {...(iconProps as unknown as TaskItemIconProps)} />}
      endComponents={errors.map(e => (
        <Pill color="error" label={e} />
      ))}
      onClick={onClick}
      onClickRemove={onClickRemove}
      active={active}
      a11yTitle={name}
      title={`#${internal_id} ${name}`}
      role={role ?? 'listitem'}
      disabled={disabled}
    />
  ) : null
}

export const TaskLineItemWrapper = ({ taskId, onClick = () => {} }: { taskId: number; onClick?: () => void }) => {
  const task = useTaskListTask(taskId)
  const stream = StreamModel.useGet(task.stream_id)
  const taskType = TaskTypeModel.useGet(task.task_type_id)

  return <TaskLineItem task={task} stream={stream} taskType={taskType} onClick={onClick} />
}
