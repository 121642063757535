import { selector } from 'recoil'
import { groupBy } from 'lodash'

import { RunbookCommentsGetResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'

export const runbookCommentsResponseState_INTERNAL = runbookAtom<RunbookCommentsGetResponse | null>({
  key: 'runbook-comments:response',
  default: null
})

export const runbookCommentsPermissionsState = selector({
  key: 'runbook-comments:permissions',
  get: ({ get }) => {
    return get(runbookCommentsResponseState_INTERNAL)?.meta?.permissions
  }
})

export const taskCommentsLookupState = selector<Record<number, RunbookComment[]>>({
  key: 'task-comments:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { comments } = get(runbookCommentsResponseState_INTERNAL) ?? {}
    const taskComments = comments?.filter(comment => !!comment.task_id)

    return groupBy(taskComments || [], 'task_internal_id')
  }
})

/* ------------------- Right nav panel comment count badge ------------------ */

// This is not persisited, it just represents the number of new comments from other
// users have been  added since loading the runbook. It clears when the comments panel
// opens and starts accumulating again when the panel is closed. The comments data above may not
// even be loaded yet when this is updating.

export const newCommentsCount_INTERNAL = runbookAtom({
  key: 'runbook-comments:count',
  default: 0
})
