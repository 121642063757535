import { CallbackInterface, TransactionInterface_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend, keyBy } from 'lodash'

import {
  RunbookShowRunbook,
  RunbookVersion,
  TaskCreateChangedTask,
  TaskListTask,
  TaskShowTask
} from 'main/services/queries/types'
import {
  accountResponseState_INTERNAL,
  newCommentsCount_INTERNAL,
  runbookCommentsResponseState_INTERNAL,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from '../../runbook/models'
import {
  RunbookChangedCustomField,
  RunbookChangedTask,
  RunbookComment,
  RunbookVersionData
} from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import { activeRightPanel_INTERNAL } from '../data-access-hooks__TEMPORARY'
import { currentUserIdState } from 'main/recoil/current-user'

// TODO: does not currently check data.id < RunBookActiveVersionModel.versionData.id) return which is done in angular.
// confirm this is still needed, see https://cutover.atlassian.net/browse/WIN-1296 from 3 years ago, and update if necessary.
export const updateVersionData =
  (transactionInterface: TransactionInterface_UNSTABLE) => (versionData: RunbookVersionData) => {
    const { set, get } = transactionInterface

    const runbookVersionResponseData = get(runbookVersionResponseState_INTERNAL)

    const updatedVersionData = produce(runbookVersionResponseData, draftRunbookVersionResponse => {
      extend(draftRunbookVersionResponse, {
        runbook_version: extend(draftRunbookVersionResponse.runbook_version, versionData)
      })
    })

    set(runbookVersionResponseState_INTERNAL, updatedVersionData)
    updateCurrentVersion(transactionInterface)(updatedVersionData.runbook_version)
  }

export const updateAllChangedTasks =
  ({ set, get }: TransactionInterface_UNSTABLE) =>
  (changedTasks: RunbookChangedTask[], task?: TaskListTask | TaskShowTask) => {
    if (!changedTasks?.length) return

    const runbookVersionResponseState = get(runbookVersionResponseState_INTERNAL)
    const runbookComponents = runbookVersionResponseState.meta.runbook_components
    const runbookComponentLookup = keyBy(runbookComponents, 'id')

    setChangedTasks(set)({ changedTasks, task, runbookComponentLookup })
  }

// Is used in recoil callbacks and transactions. The `set` available in each of those has the same type.
export const setChangedTasks =
  (set: TransactionInterface_UNSTABLE['set']) =>
  ({
    changedTasks,
    runbookComponentLookup,
    task
  }: {
    changedTasks: RunbookChangedTask[]
    runbookComponentLookup: Record<number, any>
    task?: TaskListTask | TaskShowTask
  }) => {
    set(taskListResponseState_INTERNAL, prevTaskResponse =>
      produce(prevTaskResponse, draftTaskResponse => {
        const taskLookup = keyBy(draftTaskResponse.tasks, 'id')

        const processChangedTask = (
          change: RunbookChangedTask | TaskCreateChangedTask | TaskListTask | TaskShowTask
        ) => {
          const existingTask = taskLookup[change.id]

          // WARNING: This return statement is intended to catch the scenario where a task has been updated and another created very
          // close in time. The update message (received before the 'create' response) makes reference to the newly created task, however
          // because the 'create' response comes afterwards it has not been added to recoil state. We disregard the change in this scenario
          // as it will be processed when the create response is received
          if (!existingTask && !(change as TaskListTask).field_values) return

          let updatedTaskData = change as TaskListTask

          // FIXME: updated_at` check needs to be revisited once back-end returns the correct list and
          // updated timestamps for changed tasks. Reference tickets: CBE-379 and CBE-497.

          if (task?.id === change.id) {
            // For some responses the root task and the task changes for that root task are sequential, so apply the changes in the correct order.
            updatedTaskData = (
              task.updated_at >= change.updated_at ? { ...change, ...task } : { ...task, ...change }
            ) as TaskListTask
          }

          // TODO: check - what is the response like for bulk create? do we get multiple changed tasks
          // back that don't yet exist in the task array?
          if (!existingTask) {
            draftTaskResponse.tasks.push(updatedTaskData as TaskListTask)
          } else if (existingTask.updated_at <= change.updated_at) {
            extend(existingTask, updatedTaskData)

            // re-join rb component fields because extend removes them (immutable update, confirm this)
            // @ts-ignore why does this task changes type not have field_values?
            if (change.field_values && existingTask.runbook_component_id) {
              const runbookComponent = runbookComponentLookup[existingTask.runbook_component_id]
              if (!runbookComponent) return

              existingTask.field_values.push(...(runbookComponent.field_values ?? []))
            }
          }
        }

        changedTasks.forEach(processChangedTask)
      })
    )
  }

export const updateRunbookData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (responseRunbook: Partial<RunbookShowRunbook>) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, responseRunbook)
      })
    )
  }

export const updateTasksAndVersion =
  (transactionInterface: TransactionInterface_UNSTABLE) =>
  ({
    versionData,
    changedTasks,
    task
  }: {
    versionData?: RunbookVersionData
    task?: TaskListTask | TaskShowTask
    changedTasks?: RunbookChangedTask[]
  }) => {
    if (versionData) {
      updateVersionData(transactionInterface)(versionData)
    }

    if (changedTasks) {
      updateAllChangedTasks(transactionInterface)(changedTasks, task)
    }
  }

export const updateCustomFieldsData =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (changedCustomFields: RunbookChangedCustomField[]) => {
    set(accountResponseState_INTERNAL, prevAccountResponse =>
      produce(prevAccountResponse, draftAccountResponse => {
        changedCustomFields.forEach(changedCustomField => {
          const index = draftAccountResponse.meta.custom_fields.findIndex(cf => cf.id === changedCustomField.id)
          if (index !== -1) {
            draftAccountResponse.meta.custom_fields[index] = changedCustomField
          } else {
            draftAccountResponse.meta.custom_fields.push(changedCustomField)
          }
        })
      })
    )
  }

export const updateCurrentVersion =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (newVersion: RunbookVersion) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, { current_version: newVersion, runbook_version_id: newVersion.id })
      })
    )
  }

export const useRefreshRunbookData = () => {
  return () => {
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
    window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
  }
}

export const updateAddNewComments =
  ({ set, snapshot }: CallbackInterface) =>
  async ({ comments, requesterId }: { comments: RunbookComment[]; requesterId: number }) => {
    set(runbookCommentsResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        if (draft) {
          draft.comments.push(...comments)
        }
      })
    )

    const activeRightPanel = await snapshot.getPromise(activeRightPanel_INTERNAL)
    const currentUserId = await snapshot.getPromise(currentUserIdState)
    // don't update the data for the new comment badge if the panel is open. This also covers not adding badges
    // for the current user when added through the panel, but comments are also added through bulk skipping, etc,
    // so the panel may not be open when we still don't want to update the new comment badge.
    if (currentUserId !== requesterId && activeRightPanel?.type !== 'runbook-comments') {
      set(newCommentsCount_INTERNAL, previousState => previousState + 1)
    }
  }
