import { selectorFamily } from 'recoil'

import { runbookPermissionsState } from './runbook/runbook'
import { runbookVersionPermissionsState } from './runbook-version/runbook-version'
import { taskListMetaState } from './tasks/task-list'
import {
  RunbookTeamsPermissionsResponse,
  RunbookVersionPermissionsResponse,
  RunsPermissionsResponse,
  StreamsPermissionsResponse
} from 'main/services/queries/use-runbook-versions'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { RunbookCommentsGetResponse, RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'
import { runbookCommentsPermissionsState } from './runbook/runbook-comments'

export type RunbookPermissionsResponse = RunbookGetResponse['meta']['permissions']
export type RunbookCommentsPermissionsResponse = RunbookCommentsGetResponse['meta']['permissions']
export type TasksPermissionsResponse = TaskListResponseType['meta']['permissions']

export const runbookPermission = selectorFamily<boolean, { attribute: keyof RunbookPermissionsResponse }>({
  key: 'permission:runbook',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookPermissionsState)
      return !!permissions[attribute]?.length
    }
})

export const runbookVersionPermission = selectorFamily<boolean, { attribute: keyof RunbookVersionPermissionsResponse }>(
  {
    key: 'permission:runbook-version',
    get:
      ({ attribute }) =>
      ({ get }) => {
        const permissions = get(runbookVersionPermissionsState)
        return !!permissions.runbook_version[attribute]?.length
      }
  }
)

export const runbookCommentsPermissions = selectorFamily<
  boolean,
  { attribute: keyof RunbookCommentsPermissionsResponse }
>({
  key: 'permissions:runbook-comments',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookCommentsPermissionsState)
      return !!permissions?.[attribute]?.length
    }
})

export const tasksPermission = selectorFamily<boolean, { attribute: keyof TasksPermissionsResponse }>({
  key: 'permission:tasks',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const tasksMeta = get(taskListMetaState)
      return !!tasksMeta.permissions[attribute]?.length
    }
})

export const runsPermission = selectorFamily<boolean, { attribute: keyof RunsPermissionsResponse }>({
  key: 'permission:runs',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.runs[attribute]?.length
    }
})

export const streamsPermission = selectorFamily<boolean, { attribute: keyof StreamsPermissionsResponse }>({
  key: 'permission:streams',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.streams[attribute]?.length
    }
})

export const usersTeamsPermissions = selectorFamily<boolean, { attribute: keyof RunbookTeamsPermissionsResponse }>({
  key: 'permission:users-teams',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.runbook_teams[attribute]?.length
    }
})
