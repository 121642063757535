import { Ref } from 'react'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { produce } from 'immer'
import { extend, pullAllWith } from 'lodash'

import { DependencyType, MenuListItemProps, TaskItemIcon } from '@cutover/react-ui'
import {
  accountTaskTypeLookup,
  filteredTaskListDataState,
  IntegrationRequest,
  isVersionEditable,
  ModalActiveType,
  runbookPermission,
  runbookState,
  runbookVersionStageState,
  runbookVersionState,
  runbookViewState_INTERNAL,
  streamsLookupState,
  streamsPermittedState,
  taskListLookupState,
  TaskListMenu
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import {
  useCanAddSnippetAfter,
  useCanCreateLinkedTaskAfter,
  useCanCreateTaskAfter,
  useCanDeleteTask,
  useCanStartTaskWhenStartable
} from 'main/recoil/data-access'
import {
  INTEGRATION_FINISHED_STATUSES,
  INTEGRATION_RUNNING_STATUSES,
  IntegrationActionItem,
  IntegrationFinishedStatus,
  IntegrationRunningStatus,
  IntegrationStatus,
  TaskListTask
} from 'main/services/queries/types'
import { fireIntegration } from 'main/services/queries/use-task'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { filterSelector } from 'main/recoil/shared/filters'
import { useActiveRunbookCan } from './active-runbook'

/* -------------------------------------------------------------------------- */
/*                                 Loading Ids                                */
/* -------------------------------------------------------------------------- */
export const useLoadingIdsValue = () => useRecoilValue(runbookViewState_INTERNAL).loadingIds
export const useLoadingIdsValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).loadingIds
  })
export const useLoadingIdsValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().loadingIds
  })
export const useLoadingIdAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.loadingIds[id] = true
          })
        )
  )
export const useLoadingIdRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            delete draft.loadingIds[id]
          })
        )
  )
export const useLoadingIdAddBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => (draft.loadingIds[id] = true))
          })
        )
  )
export const useLoadingIdRemoveBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => delete draft.loadingIds[id])
          })
        )
  )

/* -------------------------------------------------------------------------- */
/*                                    Menu                                    */
/* -------------------------------------------------------------------------- */
export const useMenuValue = () => {
  const { menu } = useRecoilValue(runbookViewState_INTERNAL)
  return menu
}
export const useMenuValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).menu
  })

export const useMenuValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().menu
  })

export const useMenuToggleTaskAction = () => {
  const buildMenuItems = useBuildTaskActionMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions,
        triggerRef
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: Record<string, object | undefined>
        triggerRef: Ref<HTMLElement>
      }) => {
        const currentMenu = (await snapshot.getPromise(runbookViewState_INTERNAL)).menu

        if (currentMenu.taskId === task.id && currentMenu.type === 'options') {
          return closeMenu()
        }

        const menuItems = await buildMenuItems({ task, integrationActionItem, integrationOptions })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: 'options',
          keyPrefix: 'task-opts-menu',
          items: menuItems
        })
      }
  )
}

const MIN_DEPENDENCY_MENU_WIDTH = 200
const MAX_DEPENDENCY_MENU_WIDTH = 320
const MAX_DEPENDENCY_MENU_HEIGHT = 280
export const useMenuToggleTaskDependencies = () => {
  const buildMenuItems = useBuildTaskDependencyMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        triggerRef,
        dependencyType
      }: {
        task: TaskListTask
        triggerRef: Ref<HTMLElement>
        dependencyType: DependencyType
      }) => {
        const currentMenu = (await snapshot.getPromise(runbookViewState_INTERNAL)).menu

        if (currentMenu.taskId === task.id && currentMenu.type === dependencyType) {
          return closeMenu()
        }

        const items = await buildMenuItems({ task, type: dependencyType })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: dependencyType,
          items,
          minWidth: MIN_DEPENDENCY_MENU_WIDTH,
          maxHeight: MAX_DEPENDENCY_MENU_HEIGHT,
          maxWidth: MAX_DEPENDENCY_MENU_WIDTH
        })
      },
    []
  )
}

export const useMenuClear = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.menu.isOpen = false
        draft.menu.taskId = undefined
        draft.menu.type = undefined
        draft.menu.triggerRef = undefined
        draft.menu.keyPrefix = undefined
        draft.menu.items = []
        draft.menu.minWidth = undefined
        draft.menu.maxWidth = undefined
        draft.menu.maxHeight = undefined
      })
    )
  })
export const useMenuOpen = () =>
  useRecoilCallback(({ set }) => (menu: Omit<TaskListMenu, 'open'>) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        extend(draft.menu, { ...menu, open: true })
      })
    )
  })

export const useMenuClose = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.menu.open = false
      })
    )
  })

/* ---------------------------- Menu helpers --------------------------- */

const useBuildTaskActionMenuItems = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const integrationRequest = useIntegrationRequestValue()
  const setIntegrationRequest = useIntegrationRequestAdd()

  const getCanCreateTaskAfter = useCanCreateTaskAfter()
  const getCanCreateLinkedTaskAfter = useCanCreateLinkedTaskAfter()
  const getCanAddSnippetAfter = useCanAddSnippetAfter()
  const getCanDeleteTask = useCanDeleteTask()
  const getCanStartTaskWhenStartable = useCanStartTaskWhenStartable()

  const openModal = useModalOpen()

  const openTaskCreateForm = useTaskCreateOpenForm()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: { [x: string]: {} | undefined }
      }) => {
        const { id, internal_id: internalId, predecessor_ids: predecessorIds } = task
        const runbook = await snapshot.getPromise(runbookState)
        const runbookVersion = await snapshot.getPromise(runbookVersionState)

        const lastIntegrationEvent =
          task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]
        const eventStatus: IntegrationStatus = lastIntegrationEvent.status?.toLowerCase()
        const canRefireIntegration =
          INTEGRATION_FINISHED_STATUSES.includes(eventStatus as IntegrationFinishedStatus) &&
          !integrationRequest.hasOwnProperty(task.id)
        const canAbortIntegration =
          INTEGRATION_RUNNING_STATUSES.includes(eventStatus as IntegrationRunningStatus) &&
          integrationOptions?.cancellable &&
          !integrationRequest.hasOwnProperty(task.id)

        const menuItems = [
          getCanStartTaskWhenStartable(id) &&
            canAbortIntegration &&
            ({
              label: t('actions.abortIntegration'),
              a11yTitle: t('actions.abortIntegration'),
              icon: 'cancel',
              appendDivider: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()

                openModal({
                  taskId: task.id,
                  name: integrationActionItem?.name ?? '',
                  type: 'integration-abort'
                })
              }
            } as MenuListItemProps),
          getCanStartTaskWhenStartable(id) &&
            canRefireIntegration &&
            ({
              label: t('actions.refireIntegration'),
              a11yTitle: t('actions.refireIntegration'),
              icon: 'refresh',
              appendDivider: true,
              onClick: async e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                setIntegrationRequest({ taskId: id, type: 'refire' })
                await fireIntegration({ runbookId: runbook.id, runbookVersionId: runbookVersion.id, taskId: id })
              }
            } as MenuListItemProps),
          getCanCreateTaskAfter(id) &&
            ({
              label: t('actions.addTaskAfter'),
              a11yTitle: t('actions.addTaskAfter'),
              icon: 'add',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openTaskCreateForm({ predecessor: id })
              }
            } as MenuListItemProps),
          getCanCreateLinkedTaskAfter(id) &&
            ({
              label: t('actions.addLinkedTask'),
              a11yTitle: t('actions.addLinkedTask'),
              icon: 'runbook',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'linked-runbook-add' })
              }
            } as MenuListItemProps),
          getCanAddSnippetAfter(id) &&
            ({
              label: t('actions.addSnippet'),
              a11yTitle: t('actions.addSnippet'),
              icon: 'snippet',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'snippet-add' })
              }
            } as MenuListItemProps),
          {
            label: t('actions.showCriticalPath'),
            a11yTitle: t('actions.showCriticalPath'),
            icon: 'critical-path',
            disabled: !predecessorIds.length, // Note: disabling instead of omitting so no chance of completely empty menu
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'critical_to_here' }), internalId)
            }
          } as MenuListItemProps,
          {
            label: t('actions.showAncestors'),
            a11yTitle: t('actions.showAncestors'),
            icon: 'predecessors',
            disabled: !predecessorIds.length,
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'predecessors_to_here' }), internalId)
            }
          } as MenuListItemProps,
          getCanDeleteTask(id) &&
            ({
              label: t('actions.delete'),
              a11yTitle: t('actions.delete'),
              icon: 'delete',
              destructive: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id: [id], type: 'tasks-delete' })
              }
            } as MenuListItemProps)
        ].filter(Boolean) as MenuListItemProps[]

        return menuItems
      }
  )
}

const useBuildTaskDependencyMenuItems = () => {
  const toggleTaskEdit = useToggleRightPanel('task-edit')

  return useRecoilCallback(({ snapshot }) => async ({ task, type }: { task: TaskListTask; type: DependencyType }) => {
    const taskLookup = await snapshot.getPromise(taskListLookupState)
    const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
    const streamLookup = await snapshot.getPromise(streamsLookupState)

    const dependencyIds = type === 'predecessors' ? task.predecessor_ids : task.successor_ids
    const tasks = dependencyIds.map(id => taskLookup[id])

    const menuItems = tasks
      .sort((a, b) => {
        return a.internal_id - b.internal_id
      })
      .map(task => {
        const { internal_id: internalId, name, id } = task
        const taskType = taskTypeLookup[task.task_type_id]

        const iconProps = {
          color: streamLookup[task.stream_id].color,
          icon: taskTypeIcon(taskType.icon, task.stage),
          inProgress: task.stage === 'in-progress',
          isOpaque: task.stage === 'complete',
          stageIcon: stageIconName({
            completionType: task.completion_type,
            stage: task.stage,
            startFixed: task.start_fixed
          })
        }

        const item = {
          icon: <TaskItemIcon iconSize="xsmall" {...iconProps} />,
          label: `#${internalId} ${name}`,
          onClick: () => toggleTaskEdit({ taskId: id })
        }
        return item
      })
    return menuItems
  })
}

/* -------------------------------------------------------------------------- */
/*                                    Modal                                   */
/* -------------------------------------------------------------------------- */

export const useModalValue = () => useRecoilValue(runbookViewState_INTERNAL).modal
export const useModalValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal
  })
export const useModalValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().modal
  })

export const useModalClose = () =>
  useRecoilCallback(
    ({ set }) =>
      () =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = undefined
            draft.modal.history = []
          })
        )
  )
export const useModalOpen = () =>
  useRecoilCallback(
    ({ set }) =>
      (modal: ModalActiveType) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = modal
            if (modal.type === 'tasks-csv-import') {
              // @ts-ignore
              draft.modal.active.data = { idle: true, success: false }
            }
          })
        )
  )
export const useModalContinue = () =>
  useRecoilCallback(
    ({ set }) =>
      (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = nextModal
            draft.modal.history.push(previousModal)
          })
        )
  )

export const useModalUpdate = () => {
  return useRecoilCallback(({ set }) => async (data?: object) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        // @ts-ignore
        draft.modal.active.data = data
      })
    )
  })
}

/* -------------------------------------------------------------------------- */
/*                                Selected Ids                                */
/* -------------------------------------------------------------------------- */

export const useSelectedIdsValue = () => {
  const { selectedIds } = useRecoilValue(runbookViewState_INTERNAL)
  return selectedIds
}

export const useSelectedIdsValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
  })

export const useSelectedIdsValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().selectedIds
  })

export const useSelectedIdAdd = () =>
  useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds.push(id)
      })
    )
  })

export const useSelectedIdRemove = () =>
  useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = draft.selectedIds.filter(draftId => draftId !== id)
      })
    )
  })

export const useSelectedIdBulkRemove = () =>
  useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, prev =>
      produce(prev, draft => {
        pullAllWith(draft.selectedIds, ids)
      })
    )
  })

export const useSelectedIdsOverwrite = () =>
  useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = ids
      })
    )
  })

export const useSelectedIdToggle = () => {
  const selectedIdRemove = useSelectedIdRemove()
  const selectedIdAdd = useSelectedIdAdd()
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(({ snapshot }) => async (id: number, shiftKey: boolean) => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    if (shiftKey && selectedIds.length === 1 && selectedIds[0] !== id) {
      // If holding shift and clicking a different task to the already selected one, select all tasks between the 2
      const [taskIds] = await snapshot.getPromise(filteredTaskListDataState)
      const indexOfSelected = taskIds.indexOf(selectedIds[0])
      const indexOfClicked = taskIds.indexOf(id)
      const startIndex = Math.min(indexOfSelected, indexOfClicked)
      const endIndex = Math.max(indexOfSelected, indexOfClicked)
      const newSelectedIds = taskIds.slice(startIndex, endIndex + 1)
      selectedIdsOverwrite(newSelectedIds)
    } else {
      selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
    }
  })
}

export const useSelectedIdsSelectAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(({ snapshot }) => async () => {
    const [taskIds] = await snapshot.getPromise(filteredTaskListDataState)
    selectedIdsOverwrite(taskIds)
  })
}

export const useSelectedIdsToggleAll = () => {
  const selectedIdsRemoveAll = useSelectedIdsRemoveAll()
  const selectedIdsSelectAll = useSelectedIdsSelectAll()

  return useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
  })
}

export const useSelectedIdsRemoveAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()
  return () => selectedIdsOverwrite([])
}

/* -------------------------------------------------------------------------- */
/*                               Highlight Mode                               */
/* -------------------------------------------------------------------------- */

export const useHighlightMode = () => useRecoilValue(runbookViewState_INTERNAL).highlightMode

export const useHighlightModeCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).highlightMode
  })

export const useHighlightModeCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().highlightMode
  })

export const useSetHighlightMode = () =>
  useRecoilCallback(
    ({ set }) =>
      (highlight: boolean) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.highlightMode = highlight
          })
        )
  )

/* -------------------------------------------------------------------------- */
/*                             Integration Request                            */
/* -------------------------------------------------------------------------- */

export const useIntegrationRequestValue = () => {
  const { integrationRequest } = useRecoilValue(runbookViewState_INTERNAL)
  return integrationRequest
}
export const useIntegrationRequestValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).integrationRequest
  })

export const useIntegrationRequestValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().integrationRequest
  })

export const useIntegrationRequestAdd = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (request: IntegrationRequest) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        const { taskId, type } = request
        draft.integrationRequest[taskId] = type
      })
    )
}

export const useIntegrationRequestRemove = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (taskId: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.integrationRequest[taskId]
      })
    )
}

/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export const useCanInitiateBulkEditActions = () => {
  const selectedIds = useSelectedIdsValue()
  const versionIsEditable = useRecoilValue(isVersionEditable)
  const hasUpdatePermissions = useActiveRunbookCan('update')
  const hasPermittedStreams = !!useRecoilValue(streamsPermittedState).length

  return selectedIds.length > 0 && (hasUpdatePermissions || hasPermittedStreams) && versionIsEditable
}

export const useCanInitiateBulkProgressionActions = () => {
  const selectedIds = useSelectedIdsValue()
  const hasUpdatePermissions = useActiveRunbookCan('update')
  const stage = useRecoilValue(runbookVersionStageState)

  return selectedIds.length > 0 && hasUpdatePermissions && stage === 'active'
}

export const useCanInitiateBulkEditActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = await selectedIdsCallback()
    if (selectedIds.length === 0) return false

    const versionIsEditable = await snapshot.getPromise(isVersionEditable)
    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const hasPermittedStreams = !!(await snapshot.getPromise(streamsPermittedState)).length

    return (hasUpdatePermissions || hasPermittedStreams) && versionIsEditable
  })
}

export const useCanInitiateBulkProgressionActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = await selectedIdsCallback()
    if (selectedIds.length === 0) return false

    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const stage = await snapshot.getPromise(runbookVersionStageState)

    return hasUpdatePermissions && stage === 'active'
  })
}

export const useCanInitiateBulkEditActionsCallbackSync = () => {
  const selectedIdsCallback = useSelectedIdsValueCallbackSync()

  return useRecoilCallback(({ snapshot }) => () => {
    const selectedIds = selectedIdsCallback()
    if (selectedIds.length === 0) return false

    const versionIsEditable = snapshot.getLoadable(isVersionEditable).getValue()
    const hasUpdatePermissions = snapshot.getLoadable(runbookPermission({ attribute: 'update' })).getValue()
    const hasPermittedStreams = !!snapshot.getLoadable(streamsPermittedState).getValue().length

    return (hasUpdatePermissions || hasPermittedStreams) && versionIsEditable
  })
}

export const useCanInitiateBulkProgressionActionsCallbackSync = () => {
  const selectedIdsCallback = useSelectedIdsValueCallbackSync()

  return useRecoilCallback(({ snapshot }) => () => {
    const selectedIds = selectedIdsCallback()
    if (selectedIds.length === 0) return false

    const hasUpdatePermissions = snapshot.getLoadable(runbookPermission({ attribute: 'update' })).getValue()
    const stage = snapshot.getLoadable(runbookVersionStageState).getValue()

    return hasUpdatePermissions && stage === 'active'
  })
}

/* -------------------------------------------------------------------------- */
/*                                 Task Create                                */
/* -------------------------------------------------------------------------- */

export const useTaskCreateValue = () => {
  return useRecoilValue(runbookViewState_INTERNAL).taskCreate
}

export const useTaskCreateValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).taskCreate
  })

export const useTaskCreateValueCallbackSync = () =>
  useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(runbookViewState_INTERNAL).getValue().taskCreate
  })

export const useTaskCreateToggleForm = () =>
  useRecoilCallback(({ set }) => async ({ predecessor }) => {
    set(runbookViewState_INTERNAL, prev =>
      produce(prev, draft => {
        if (draft.taskCreate.predecessor === predecessor) {
          draft.taskCreate.predecessor = undefined
          draft.taskCreate.name = undefined
        } else {
          draft.taskCreate.predecessor = predecessor
          draft.taskCreate.name = undefined
        }
      })
    )
  })

export const useTaskCreateOpenForm = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const setFromPredecessor = ({ predecessor }: { predecessor?: number }) =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.predecessor = predecessor
        draft.taskCreate.name = undefined
      })
    )

  return setFromPredecessor
}

export const useTaskCreateCloseForm = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const onClose = () =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.predecessor = undefined
        draft.taskCreate.name = undefined
      })
    )

  return onClose
}

export const useTaskCreateSetName = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const onName = (name: string | undefined) =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.name = name
      })
    )

  return onName
}
