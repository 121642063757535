import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

import { useCurrentUser } from 'main/recoil/data-access'
import { useInstanceName, useUserAnonymization } from '../../services/hooks'
import { ConfigModel } from 'main/data-access'

export const useDatadog = () => {
  const currentUser = useCurrentUser()
  const { datadogRumAppId, datadogRumClientId, datadogRumDatacenter, version } = ConfigModel.useGet()
  const { anonUserId } = useUserAnonymization()
  const instanceName = useInstanceName()

  useEffect(() => {
    if (!datadogRumAppId || !datadogRumClientId) return

    datadogRum.init({
      applicationId: datadogRumAppId,
      clientToken: datadogRumClientId,
      site: datadogRumDatacenter === 'US' ? 'datadoghq.com' : 'datadoghq.eu',
      silentMultipleInit: true,
      trackInteractions: true,
      version: version.label,
      service: 'react-app',
      // Note: we require intakeApiVersion 1 as cloud does not currently support v2.
      // We also require v3 of datadog/browser-rum package, as v4 removes support for intakeApiVersion 1.
      intakeApiVersion: 1
    })
    datadogRum.addRumGlobalContext('instance', {
      name: instanceName
    })
    datadogRum.addRumGlobalContext('usr', {
      id: anonUserId,
      isCutoverUser: currentUser?.email.search(/@cutover.com/) !== -1 ?? false
    })

    return () => {
      datadogRum.addRumGlobalContext('usr', {})
    }
  }, [])
}
