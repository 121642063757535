import { produce } from 'immer'
import { extend } from 'lodash'
import { TransactionInterface_UNSTABLE } from 'recoil'

import { RunbookVersion } from 'main/services/queries/types'
import { runbookResponseState_INTERNAL } from 'main/recoil/runbook'

export const updateCurrentVersion =
  ({ set }: TransactionInterface_UNSTABLE) =>
  (newVersion: RunbookVersion) => {
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, { current_version: newVersion, runbook_version_id: newVersion.id })
      })
    )
  }
