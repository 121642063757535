import { useState } from 'react'

import { RunbookActivityPage } from '../components/activities/runbook-activity-page'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const ActivitiesPageConnector = () => {
  const [runbookId, setRunbookId] = useState<number | null>(null)
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [incidentId, setIncidentId] = useState<number | null>(null)
  const [timezone, setTimezone] = useState('')

  return (
    <ReactAngularConnector
      mountEvent="show-runbook-activities"
      unmountEvent="hide-runbook-activities"
      onMount={({ runbookId, currentUserId, incidentId, timezone }) => {
        setRunbookId(runbookId)
        setCurrentUserId(currentUserId)
        setIncidentId(incidentId)
        setTimezone(timezone)
      }}
    >
      {runbookId && currentUserId && incidentId && (
        <RunbookActivityPage
          timezone={timezone}
          incidentId={incidentId}
          runbookId={runbookId}
          currentUserId={currentUserId}
        />
      )}
    </ReactAngularConnector>
  )
}
