import { t } from 'i18next'
import { isEmpty } from 'lodash'
import * as yup from 'yup'

import { urlRegex } from '@cutover/react-ui'
import { isJsonString } from 'main/services/json-utils'

export const dataSourceFormValidationSchema = (settings?: any) => {
  const settingsSchema = !isEmpty(settings) ? buildSettingsSchema(settings) : { authType: yup.string() }

  return yup.object({
    _step: yup.number().oneOf([1, 2]),
    id: yup.number().nullable(),

    // step 1
    name: yup.string().required(t('dataSources.formData.name.requiredValidationMessage')),
    url: yup.string().when('settings.authType', {
      is: (authType: string) => authType === 'Cutover Connect',
      then: schema => schema.notRequired(),
      otherwise: schema => {
        return schema
          .required(t('dataSources.formData.url.requiredValidationMessage'))
          .matches(urlRegex, { message: t('dataSources.formData.url.invalid'), excludeEmptyString: true })
      }
    }),
    http_method: yup.string().when('settings.authType', {
      is: (authType: string) => authType === 'Cutover Connect',
      then: schema => schema.notRequired(),
      otherwise: schema => schema.required(t('dataSources.formData.httpMethod.requiredValidationMessage'))
    }),
    search_parameter_name: yup
      .string()
      .required(t('dataSources.formData.searchParameterName.requiredValidationMessage')),
    http_headers: yup.string().when('settings.authType', {
      is: (authType: string) => authType === 'Cutover Connect',
      then: schema => schema.notRequired(),
      otherwise: schema => {
        return schema
          .required(t('dataSources.formData.httpHeaders.jsonValidationMessage'))
          .test('is-json', t('dataSources.formData.httpHeaders.jsonValidationMessage'), value => isJsonString(value))
      }
    }),
    settings: yup
      .object()
      .shape(settingsSchema)
      .when('authType', {
        is: (authType: string) => ['Token Auth', 'OAuth', 'AWS STS SigV4', 'Cutover Connect'].includes(authType),
        then: schema => schema.required('invalid'),
        otherwise: schema => schema.notRequired()
      }),

    // step 2
    query: yup
      .object()
      .required(t('dataSources.formData.query.requiredValidationMessage'))
      .shape({
        query_string: yup.string()
      })
      .when('_step', {
        is: (step: number) => step === 2,
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      }),
    json_root: yup
      .string()
      .required(t('dataSources.formData.json_root.requiredValidationMessage'))
      .when('_step', {
        is: (step: number) => step === 2,
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      }),
    json_mappings: yup.mixed()
  })
}

function buildSettingsSchema(actionConfigSettings: any | undefined) {
  const settings = actionConfigSettings ?? {}

  if (!isEmpty(settings)) {
    const settingsSchema = Object.entries(settings).reduce((fields: any, [settingKey]) => {
      const authTypes = (settings[settingKey]?.dependency || [])
        .map((dep: { [key: string]: string }) => {
          if (dep.dependentSetting === 'auth_type') {
            return dep.value
          }
        })
        .filter((authType: string) => authType)
      if (!['authType', 'oauthFlow'].includes(settingKey) && settings[settingKey]?.required) {
        fields[settingKey] = yup.string()
        if (['oauthAuthorizeUrl', 'oauthRedirectUri'].includes(settingKey)) {
          fields[settingKey] = (fields[settingKey] as yup.StringSchema)
            .when('oauthFlow', {
              is: (oauthFlow: string) =>
                authTypes.includes('OAuth') &&
                (oauthFlow === 'Authorization Code' ||
                  (oauthFlow === 'Client Credentials' &&
                    !['oauthAuthorizeUrl', 'oauthRedirectUri'].includes(settingKey))),
              then: schema => schema.required('invalid'),
              otherwise: schema => schema.notRequired()
            })
            .when('authType', {
              is: (authType: string) =>
                ['No Auth', 'Token Auth', 'AWS STS SigV4', 'Cutover Connect'].includes(authType),
              then: schema => schema.notRequired()
            })
        } else if (['requestType', 'webhookUrl'].includes(settingKey)) {
          fields[settingKey] = (fields[settingKey] as yup.StringSchema).when('authType', {
            is: (authType: string) => authType === 'Cutover Connect',
            then: schema => schema.notRequired()
          })
        } else {
          fields[settingKey] = (fields[settingKey] as yup.StringSchema).when('authType', {
            is: (authType: string) => authTypes.includes(authType),
            then: schema => schema.required('invalid'),
            otherwise: schema => schema.notRequired()
          })
        }
      } else {
        fields[settingKey] = yup.mixed().nullable()
      }

      return fields
    }, {} as { [key: string]: yup.StringSchema })

    // Additional settings schema for connect
    settingsSchema['route_name'] = yup
      .string()
      .required()
      .when('authType', {
        is: (authType: string) => authType === 'Cutover Connect',
        then: schema => schema.required(t('dataSources.formData.routeName.requiredValidationMessage')),
        otherwise: schema => schema.notRequired()
      })

    return settingsSchema
  }
}
