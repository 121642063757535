import { useMemo } from 'react'
import styled from 'styled-components'

import { Button } from '@cutover/react-ui'
import {
  useCanTaskFinish,
  useCanTaskStart,
  useCurrentUserId,
  useTaskListTask,
  useTaskProgression
} from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { ActiveRunModel, RunbookViewModel } from 'main/data-access'

export const StartableTaskButton = ({ taskId }: { taskId: number }) => {
  const task = useTaskListTask(taskId)
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.startable' })
  const isLocked = useMemo(() => (task.start_fixed ? task.start_fixed * 1000 > Date.now() : false), [task.start_fixed])
  const runMode = ActiveRunModel.useMode()
  const canStartTask = useCanTaskStart()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentUserId = useCurrentUserId()!
  const hasUserStartedTask = task.started_user_ids?.includes(currentUserId)
  const { resolveProgressionModalRecoilCallback, startOrFinishTask: startTask } = useTaskProgression()
  const modalOpen = RunbookViewModel.useOnAction('modal:open')

  const handleClickStartTask = async () => {
    const nextModal = await resolveProgressionModalRecoilCallback(task.id)
    nextModal ? modalOpen(nextModal) : startTask(task.id)
  }

  if (canStartTask(taskId)) {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        icon="play"
        label={isLocked ? t('fixedStart') : t('start')}
        onClick={handleClickStartTask}
      />
    )
  } else if (hasUserStartedTask) {
    return <TaskActionButton label={t('pendingApproval')} icon="play" disabled />
  } else if (!isLocked) {
    return <TaskActionButton label={t('startable')} icon="play" disabled />
  } else {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        icon="play"
        label={t('fixedStart')}
        onClick={handleClickStartTask}
      />
    )
  }
}
export const InProgressTaskButton = ({ taskId }: { taskId: number }) => {
  const task = useTaskListTask(taskId)
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.inProgress' })
  const canFinishTask = useCanTaskFinish()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentUserId = useCurrentUserId()!
  const runMode = ActiveRunModel.useMode()
  const hasUserEndedTask = task.ended_user_ids?.includes(currentUserId)
  const { resolveProgressionModalRecoilCallback, startOrFinishTask: finishTask } = useTaskProgression()
  const modalOpen = RunbookViewModel.useOnAction('modal:open')

  const handleClickFinishTask = async () => {
    const nextModal = await resolveProgressionModalRecoilCallback(task.id)
    nextModal ? modalOpen(nextModal) : finishTask(task.id)
  }

  if (canFinishTask(taskId)) {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        label={t('finishTask')}
        onClick={handleClickFinishTask}
        icon="check-dashed"
      />
    )
  } else if (hasUserEndedTask) {
    return <TaskActionButton label={t('pendingApproval')} icon="check-dashed" disabled />
  } else {
    return <TaskActionButton label={t('inProgress')} disabled />
  }
}
export const CompletedTaskButton = ({ taskId }: { taskId: number }) => {
  const task = useTaskListTask(taskId)
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.complete' })

  // TODO: Address completion type in the context of linked resources. Angular computes a
  // frontend attribute for it. See angular's task_active_model _getTaskCompletionStatus function
  switch (task.completion_type) {
    case 'complete_normal':
      return <TaskActionButton label={t('normal')} icon="check" disabled />
    case 'complete_abandoned':
      return <TaskActionButton label={t('abandoned')} icon="close" disabled />
    case 'complete_skipped':
      return <TaskActionButton label={t('skipped')} icon="skip" disabled />
    default:
      return null
  }
}

const TaskActionButton = styled(Button).attrs({
  full: true,
  primary: true,
  'data-testid': 'edit-panel-task-action-button'
})``
