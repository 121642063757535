import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import styled from 'styled-components'

import {
  LegacyBox as Box,
  colors,
  duration,
  LegacyFlexItem as FlexItem,
  px2rem,
  LegacyTableBase as Table
} from '@cutover/react-ui'
import { StreamSummaryProgressBar } from './stream-summary-progress-bar'
import { StreamSummaryData } from '../../types'

type StreamRowItemProps = {
  stream: StreamSummaryData
  parent?: StreamSummaryData
  timezone: string
  runbookIsActive: boolean
}

export const StreamRowItem = ({ stream, timezone, runbookIsActive }: StreamRowItemProps) => {
  const truncatedStreamName = stream.name?.length > 60 ? `${stream.name.substring(0, 59)}...` : stream.name

  const startTimeDiffSign =
    stream.start && stream.startLatestPlanned && stream.start - stream.startLatestPlanned > 0 ? '+' : '-'
  const endTimeDiffSign = stream.end && stream.endLatestPlanned && stream.end - stream.endLatestPlanned > 0 ? '+' : '-'

  const startDurationDisplay =
    stream.start && stream.startLatestPlanned && duration(stream.start - stream.startLatestPlanned)
  const endDurationDisplay = stream.end && stream.endLatestPlanned && duration(stream.end - stream.endLatestPlanned)

  const durationDisplay = stream.end && stream.start && duration(stream.end - stream.start)

  return (
    <>
      {/* // Note: this is bad code, but this whole comp and related stories use incorrect builders/types so the whole thing needs reworked */}
      {stream.hasOwnProperty('tasks_count') && stream.tasks_count !== undefined && stream.tasks_count > 0 && (
        <Table.Row key={stream.id}>
          {stream.parent_id ? (
            <Table.Cell
              width="20%"
              css={{
                marginLeft: px2rem(10),
                marginRight: px2rem(-10)
              }}
            >
              {truncatedStreamName}
            </Table.Cell>
          ) : (
            <Table.Cell width="20%">{truncatedStreamName}</Table.Cell>
          )}
          <Table.Cell width="25%">
            <FlexItem key={`f-${stream.id}`}>
              <StreamSummaryProgressBar stream={stream} />
            </FlexItem>
          </Table.Cell>
          <Table.Cell width="20%">
            <StartDiv
              hasStarted={stream.stage !== 'default' || stream.totalInProgressTasks || stream.totalCompletedTasks}
            >
              {stream.start && dateFormatter(stream.start, timezone)}
              {stream.start && stream.startLatestPlanned && runbookIsActive && (
                <DiffDiv isOnTime={startTimeDiffSign === '-'}>
                  {startDurationDisplay && `${startTimeDiffSign}${startDurationDisplay}`}
                </DiffDiv>
              )}
            </StartDiv>
          </Table.Cell>
          <Table.Cell width="20%">
            <FinishDiv isComplete={stream.stage === 'complete' && stream.totalCompletedTasks === stream.tasks_count}>
              {stream.end && dateFormatter(stream.end, timezone)}
              {stream.end && stream.endLatestPlanned && runbookIsActive && (
                <DiffDiv isOnTime={endTimeDiffSign === '-'}>
                  {endDurationDisplay && `${endTimeDiffSign}${endDurationDisplay}`}
                </DiffDiv>
              )}
            </FinishDiv>
          </Table.Cell>
          <Table.Cell width="15%">{durationDisplay}</Table.Cell>
        </Table.Row>
      )}
    </>
  )
}

const dateFormatter = (seconds: number, timezone: string): string => {
  const dateInZone = utcToZonedTime(seconds * 1000, timezone)
  const formatted = format(dateInZone, 'd MMM HH:mm')
  return formatted
}

const StartDiv = styled(Box)`
  opacity: ${props => (props.hasStarted ? 1 : 0.5)};
`

const FinishDiv = styled(Box)`
  opacity: ${props => (props.isComplete ? 1 : 0.5)};
`

const DiffDiv = styled(Box)`
  display: block;
  color: ${props => (props.isOnTime ? colors.success : colors.warningLate)};
`
