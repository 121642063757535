import { useMemo } from 'react'
import { format, fromUnixTime } from 'date-fns'

import { Box, Message, Modal, Text } from '@cutover/react-ui'
import { useCurrentUser, useTaskListTask, useTaskProgression, useTaskProgressionState } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  CustomFieldModel,
  RunbookViewModel,
  TaskTypeModel
} from 'main/data-access'

type TaskFixedStartOverrideProps = {
  id: number
  onClose: () => void
}

export const TaskFixedStartOverride = ({ id, onClose }: TaskFixedStartOverrideProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskFixedStartOverrideModal' })
  const canUpdate = ActiveRunbookModel.useCan('update')
  const task = useTaskListTask(id)
  const taskType = TaskTypeModel.useGet(task.task_type_id)
  const startFixedTime = format(fromUnixTime(task.start_fixed as number), 'dd/MM/yy HH:mm')
  const modalClose = RunbookViewModel.useOnAction('modal:close')
  const modalContinue = RunbookViewModel.useOnAction('modal:continue')
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const { name: accountName } = ActiveAccountModel.useGet()
  const customFields = CustomFieldModel.useGetAll()
  const currentUser = useCurrentUser()
  const runbook = ActiveRunbookModel.useGet()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        taskType,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbook,
        from: { id, type: 'task-override-fixed-start' }
      }),
    [task, taskType, accountName, progressionState, customFields, currentUser, resolveProgressionModalCallback]
  )

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      if (!canUpdate) return modalClose()
      return nextModal ? modalContinue(nextModal, { id, type: 'task-override-fixed-start' }) : startOrFinishTask(id)
    },
    onClose,
    confirmIcon: canUpdate ? 'caret-right' : undefined,
    confirmText: t(canUpdate ? 'confirmTextAdmin' : 'confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-override-start-fixed"
      open
      animate={nextModal ? 'in' : true}
      title={t('title')}
      confirmText={confirmText as string}
      confirmIcon={confirmIcon}
      onClickConfirm={handleSubmit as any}
      onClose={handleClose}
      hasCancelButton={hasCancelButton}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box>
          <Text>{t('content', { startFixedTime })}</Text>
          {!canUpdate && <Text>{t('contentNotAdmin')}</Text>}
        </Box>
      )}
    </Modal>
  )
}
