import { Simplify } from 'type-fest'

import { RunbookShowRunbook, RunbookTypeType } from 'main/services/queries/types'
import {
  useActiveRunbookCan,
  useActiveRunbookCanAction,
  useActiveRunbookCanActionCallback,
  useActiveRunbookId,
  useActiveRunbookIdCallback,
  useActiveRunbookLoadableCallback,
  useActiveRunbookLoadableValue,
  useActiveRunbookOnAction,
  useActiveRunbookRunbookType,
  useActiveRunbookRunbookTypeCallback,
  useGetActiveRunbook,
  useGetActiveRunbookCallback
} from '../hooks'
import { ActiveModelInterfaceBase, ActiveModelInterfaceWithActions as WithActions } from './types'
import { RunbookResponseMetaPermissions } from 'main/services/api/data-providers/runbook-types'

export type ActiveRunbookModelType = Simplify<
  ActiveModelInterfaceBase<RunbookShowRunbook, RunbookResponseMetaPermissions> &
    WithActions<RunbookShowRunbook, 'Runbook', 'update' | 'merge'> & {
      useRunbookType: () => RunbookTypeType
      useRunbookTypeCallback: () => () => Promise<RunbookTypeType>
    }
>

export const ActiveRunbookModel: ActiveRunbookModelType = {
  useGet: useGetActiveRunbook,
  useGetCallback: useGetActiveRunbookCallback,

  useGetLoadable: useActiveRunbookLoadableValue,
  useGetLoadableCallback: useActiveRunbookLoadableCallback,

  useCan: useActiveRunbookCan,

  useOnAction: useActiveRunbookOnAction,
  // TODO: these canActions mimic the permissions at the moment. When we have more update / merge functionality
  // in react runbook, update these functions so they take account of whether the runbook is in planning mode /
  // dynamic etc. At the time of writing I can't confidently say that that will always be the case
  useCanAction: useActiveRunbookCanAction,
  useCanActionCallback: useActiveRunbookCanActionCallback,

  useId: useActiveRunbookId,
  useIdCallback: useActiveRunbookIdCallback,

  useRunbookType: useActiveRunbookRunbookType,
  useRunbookTypeCallback: useActiveRunbookRunbookTypeCallback
}
