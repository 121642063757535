import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MergeDeep } from 'type-fest'

import { Accordion, Badge, Box, IconButton, Select, SpinnerIcon, TextArea, useNotify } from '@cutover/react-ui'
import { TextAreaField } from 'main/components/shared/form'
import { RunbookEditDisplaySettingsPanel as DisplaySettingsPanel } from './display-settings-panel'
import { RunbookEditRtoPanel as RtoPanel } from './rto-panel'
import { RunbookEditOtherDetailsPanel as OtherDetailsPanel } from './other-details-panel'
import { RagStatusFields } from './rag-status-fields'
import { RunbookEditRolesPanel as RolesPanel } from './roles-panel'
import { RunbookEditTemplateSettingsPanel as TemplateSettingsPanel } from './template-settings-panel'
import { RunbookEditTimingModeFields as TimingModeFields } from './timing-mode-fields'
import { RunbookEditWebhooksPanel } from './webhooks-panel'
import { TimezoneSelect } from '../../form/timezone-select'
import { useFolderOptions } from '../../form/use-folder-options'
import { useLanguage } from 'main/services/hooks'
import {
  Account,
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  FieldValue,
  RoleType,
  RunbookEditRunbook,
  RunbookTypeType,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { RunbookUpdatePayload } from 'main/services/queries/use-update-runbook'
import { CustomFieldsGroupsForm } from '../../custom-field-form/custom-field-groups-form'
import { useRunbookGenerateDescription } from 'main/services/queries/use-runbook-edit'
import { ConfigModel } from 'main/data-access'
import { usePermitRoleAddition } from '../../hooks/runbook'

export type RunbookEditFormType = MergeDeep<
  RunbookUpdatePayload,
  {
    runbook: {
      field_values?: Record<string, FieldValue>
      // collect the form value via role_types but then transform it into roles before submitting
      role_types: RoleType[]
    }
  }
>

type RunbookEditFormProps = {
  taskLookup: Record<string, TaskListTask>
  taskTypeLookup: Record<string, TaskType>
  streamLookup: Record<string, StreamListStream>
  disabled?: boolean
  readOnly?: boolean
  runbook: RunbookEditRunbook
  runbookType: RunbookTypeType
  runbookTypes: RunbookTypeType[]
  account: Account
  projects: PermittedProject[]
  customFields?: CustomField[]
  customFieldsByGroupId?: Record<number, CustomField[]>
  customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  customFieldUsers?: CustomFieldUser[]
  descriptionGenerated: boolean
  setDescriptionGenerated: Dispatch<SetStateAction<boolean>>
}

export const RunbookEditForm = ({
  taskLookup,
  taskTypeLookup,
  streamLookup,
  disabled,
  readOnly,
  runbook,
  runbookType,
  runbookTypes,
  account,
  projects,
  customFields = [],
  customFieldsByGroupId = {},
  customFieldGroupsLookup = {},
  customFieldUsers = [],
  descriptionGenerated,
  setDescriptionGenerated
}: RunbookEditFormProps) => {
  const {
    formState: { errors },
    register
  } = useFormContext<RunbookEditFormType>()
  const isSnippet = runbook.template_type === 'snippet'
  const isRunbook = runbook.template_type === 'off'
  const isIncident = runbookType?.incident
  const isRtoEnabled = runbookType?.enable_rto
  const permitRoleAddition = usePermitRoleAddition(runbookType)

  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const isAIFeaturesEnabled = ConfigModel.useIsFeatureEnabled('ai_features')

  const timingModeLock = ['active', 'complete', 'cancelled'].includes(runbook.current_version.stage)

  return (
    <Box flex={false} pad={{ bottom: '64px' }}>
      <NameField disabled={disabled} readOnly={readOnly} defaultValue={runbook.name} />

      {!isAIFeaturesEnabled ? (
        <TextArea
          {...register('runbook.description', {
            disabled
          })}
          label={t('otherDetails.description.label')}
          readOnly={readOnly}
        />
      ) : (
        <DescriptionWithAiGenerateButton
          descriptionGenerated={descriptionGenerated}
          setDescriptionGenerated={setDescriptionGenerated}
          runbook={runbook}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}

      {!isSnippet && <FolderField disabled={disabled} readOnly={readOnly || !projects.length} projects={projects} />}
      {!isSnippet && <TimezoneField runbook={runbook} disabled={disabled} readOnly={readOnly} />}
      {isRunbook && !isIncident && (
        <TimingModeFields
          runbook={runbook}
          disabled={disabled || timingModeLock}
          readOnly={readOnly || timingModeLock}
        />
      )}
      {isRunbook && !isIncident && (
        <RagStatusFields<RunbookEditFormType>
          name="runbook.status"
          statusMessageFieldName="runbook.status_message"
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      <Accordion>
        {isRtoEnabled && !isRunbook && !isSnippet && (
          <RtoPanel
            runbook={runbook}
            readOnly={readOnly}
            customFields={customFields}
            taskLookup={taskLookup}
            taskTypeLookup={taskTypeLookup}
            streamLookup={streamLookup}
          />
        )}
        <RolesPanel
          runbook={runbook}
          accountId={account.id}
          disabled={disabled || !permitRoleAddition}
          readOnly={readOnly}
        />
        {!isSnippet && (
          <CustomFieldsGroupsForm
            customFields={customFields}
            customFieldGroupsLookup={customFieldGroupsLookup}
            groupedCustomFields={customFieldsByGroupId}
            customFieldUsers={customFieldUsers}
            iconColor={runbook.project.color}
            namePrefix="runbook."
            errors={errors.runbook}
          />
        )}
        {!isRunbook && !isIncident && (
          <TemplateSettingsPanel runbook={runbook} disabled={disabled} readOnly={readOnly} />
        )}
        {!isSnippet && (
          <DisplaySettingsPanel
            runbook={runbook}
            runbookType={runbookType}
            runbookTypes={runbookTypes}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
        <OtherDetailsPanel runbook={runbook} accountSlug={account.slug} disabled={disabled} readOnly={readOnly} />
        {!isSnippet && <RunbookEditWebhooksPanel runbook={runbook} />}
      </Accordion>
    </Box>
  )
}

const NameField = ({
  defaultValue,
  disabled,
  readOnly
}: {
  defaultValue: string
  disabled?: boolean
  readOnly?: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { formState } = useFormContext<RunbookEditFormType>()

  return (
    <TextAreaField<RunbookEditFormType>
      label={t('title.label')}
      required
      autoFocus
      readOnly={readOnly}
      disabled={disabled}
      hasError={!!formState.errors.runbook?.name}
      defaultValue={defaultValue}
      name="runbook.name"
    />
  )
}

const FolderField = ({
  disabled,
  readOnly,
  projects
}: {
  disabled?: boolean
  readOnly?: boolean
  projects: PermittedProject[]
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { control } = useFormContext<RunbookEditFormType>()
  const flattenedFolderHierarchy = useFolderOptions({ folders: projects })

  return (
    <Controller
      name={'runbook.project_id'}
      control={control}
      render={({ field: { onChange, value, onBlur, ref }, fieldState: { error } }) => (
        <Select
          data-testid="runbook-edit-folder-select"
          labelKey="name"
          optionToString={option => option.name_hierarchy ?? option.name}
          icon="folder-open"
          valueKey="id"
          filterKeys={['name']}
          disabled={disabled}
          hasError={!!error}
          inlineError={error?.message}
          inputRef={ref}
          label={t('folder.label')}
          onBlur={onBlur}
          onChange={onChange}
          options={flattenedFolderHierarchy}
          placeholder={t('folder.placeholder')}
          readOnly={readOnly}
          required
          value={value}
        />
      )}
    />
  )
}

const TimezoneField = ({
  runbook,
  disabled,
  readOnly
}: {
  runbook: RunbookEditRunbook
  disabled?: boolean
  readOnly?: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const { formState, control, watch } = useFormContext<RunbookEditFormType>()
  const startScheduled = watch(`runbook.runbook_versions_attributes.${0}.start_scheduled`)
  const targetDate = useMemo(() => (startScheduled ? new Date(startScheduled) : undefined), [startScheduled])

  return (
    <Controller
      name={'runbook.timezone'}
      control={control}
      defaultValue={runbook.timezone}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <TimezoneSelect
          targetDate={targetDate}
          disabled={disabled}
          hasError={!!formState.errors.runbook?.timezone}
          inlineError={formState.errors.runbook?.timezone?.message}
          inputRef={ref}
          label={t('timezone.label')}
          name="runbook.timezone"
          onBlur={onBlur}
          onChange={onChange}
          placeholder={t('timezone.placeholder')}
          readOnly={readOnly}
          helpText={t('timezone.tooltip')}
          value={value}
          customFirstOption={{
            id: 'automatic',
            name: 'Automatic'
          }}
        />
      )}
    />
  )
}

type DescriptionWithAiGenerateButtonProps = {
  readOnly?: boolean
  disabled?: boolean
  runbook: RunbookEditRunbook
  descriptionGenerated: boolean
  setDescriptionGenerated: Dispatch<SetStateAction<boolean>>
}

const DescriptionWithAiGenerateButton = ({
  disabled = false,
  readOnly = false,
  runbook,
  descriptionGenerated,
  setDescriptionGenerated
}: DescriptionWithAiGenerateButtonProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const notify = useNotify()
  const [startGenerating, setStartGenerating] = useState(false)
  const { register, setValue, getValues } = useFormContext<RunbookEditFormType>()

  const { isFetching } = useRunbookGenerateDescription(runbook?.id, {
    enabled: startGenerating,
    onSuccess: (response: any) => {
      setStartGenerating(false)
      setDescriptionGenerated(true)
      setValue('runbook.description', response.description, { shouldDirty: true })

      notify.success(t('otherDetails.description.ai_suggestions.successful'))
    },
    onError: () => {
      setStartGenerating(false)
      setDescriptionGenerated(false)
      notify.error('otherDetails.description.ai_suggestions.failed')
    }
  })

  const handleGenerateDescription = async () => {
    setStartGenerating(true)
  }

  const actionButtonLabel = (): string => {
    if (!runbook.description && !getValues('runbook.description')) {
      return t('otherDetails.description.ai_suggestions.ctaLabels.aiGenerate')
    } else if (descriptionGenerated || runbook.ai_generated_description) {
      return t('otherDetails.description.ai_suggestions.ctaLabels.regenerate')
    } else {
      return t('otherDetails.description.ai_suggestions.ctaLabels.replace')
    }
  }

  return (
    <Box css="position: relative" direction="row" justify="between" alignContent="flex-start">
      <Box css="margin-right: 10px; z-index: 1" flex={{ grow: 1 }}>
        <TextArea
          {...register('runbook.description', {
            disabled: disabled || isFetching
          })}
          label={
            t('otherDetails.description.label') +
            `${
              descriptionGenerated || runbook.ai_generated_description
                ? t('otherDetails.description.ai_suggestions.aiSummary')
                : ''
            }`
          }
          readOnly={readOnly}
        />
      </Box>
      {isFetching ? (
        <SpinnerIcon color="text-light" css="opacity: 0.5; position: absolute; top: 10px; right: 0; zIndex: 2" />
      ) : (
        <Box css="position: absolute; top: 10px; right: 0; z-index: 2">
          <Badge color="feature-pink">
            <IconButton
              size="medium"
              label={actionButtonLabel()}
              icon="rocket"
              tipPlacement="top"
              onClick={handleGenerateDescription}
            />
          </Badge>
        </Box>
      )}
    </Box>
  )
}
