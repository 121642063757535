import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { Box, CodeEditor, colors, Icon, Modal, Select, Text, TextInput } from '@cutover/react-ui'
import { Action } from './types'
import { useLanguage } from 'main/services/hooks'

/**
 * @deprecated refer to the new integration-debug-modal.tsx which is react specific, without dependency on events.
 * Do not use this component as reference, it will be removed when angular task list is decomissioned.
 */
export const IntegrationDebugModalFromEvent = () => {
  const { t } = useLanguage('integrationDebug')
  const [isOpen, setIsOpen] = useState(false)
  const [action, setAction] = useState<Action | null>(null)
  const [tabs, setTabs] = useState<Array<string>>([])
  const [selectedTab, setSelectedTab] = useState<string | null>(null)

  const selectTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const closeModal = () => {
    setAction(null)
    setIsOpen(false)
  }

  useEffect(() => {
    const openModal = ({ action }: { action: Action }) => {
      setAction(action)
      setIsOpen(true)
    }
    eventManager.on('open-integration-debug-modal', openModal)
    return () => {
      eventManager.off('open-integration-debug-modal', openModal)
    }
  }, [])

  useEffect(() => {
    setTabs(['request', 'response'].filter(tab => tabIsApplicable(tab, action)))
  }, [action])

  useEffect(() => {
    setSelectedTab(tabs[0])
  }, [tabs])

  return (
    <Modal open={isOpen} onClose={closeModal} hideFooter title={action?.modal_title || t('header')}>
      {action && action.endpoint_failed && <Box>{parse(t('info'))}</Box>}
      <Box align="center" direction="row" margin={{ bottom: '16px' }}>
        {tabs.map(tab => (
          <StyledTabButton key={tab} active={selectedTab === tab} onClick={() => selectTab(tab)}>
            {t('tabs.title', { context: tab })}
          </StyledTabButton>
        ))}
        {action && action.endpoint_failed && <Icon icon="alert" color="error" />}
      </Box>

      {action && selectedTab === 'request' && (
        <Box direction="column">
          {action.auth_type && (
            <Select
              label={t('executionMethod')}
              disabled
              value={action.auth_type}
              onChange={() => {}}
              options={[{ label: action.auth_type, value: action.auth_type }]}
            />
          )}
          {action.request_type && (
            <Select
              label={t('requestType')}
              disabled
              value={action.request_type}
              onChange={() => {}}
              options={[{ label: action.request_type, value: action.request_type }]}
            />
          )}
          {action.url && <TextInput label={'URL'} value={action.url} readOnly />}
          {action.message_id && <TextInput label={'SQS Message ID'} value={action.message_id} readOnly />}
          {action.request_headers && <CodeEditorSection title={t('requestHeaders')} value={action.request_headers} />}
          {action.request_type === 'GET' && (
            <CodeEditorSection title={t('requestParameters')} value={action.request_parameters ?? ''} />
          )}
          {action.request_body && <CodeEditorSection title={t('requestBody')} value={action.request_body} />}
        </Box>
      )}
      {action && selectedTab === 'response' && (
        <Box direction="column">
          {action.response_status && (
            <TextInput
              defaultValue={action.response_status}
              label={t('statusCode')}
              readOnly
              hasError={action.endpoint_failed}
            />
          )}
          {action.response_headers && (
            <CodeEditorSection title={t('responseHeaders')} value={action.response_headers} />
          )}
          {action.response_body && <CodeEditorSection title={t('responseBody')} value={action.response_body} />}
        </Box>
      )}
    </Modal>
  )
}

const formatJSON = (val = '') => {
  try {
    const res = JSON.parse(val)
    return JSON.stringify(res, null, 2)
  } catch {
    return val
  }
}

const CodeEditorSection = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box direction="column">
      <Text
        as="h2"
        css={`
          font-size: 13px;
          color: ${colors.textLight};
        `}
      >
        {title}
      </Text>
      <CodeEditor defaultLanguage="json" value={formatJSON(value)} readOnly />
    </Box>
  )
}

const StyledTabButton = styled.button.attrs(() => ({
  type: 'button'
}))<{ active?: boolean }>`
  font-weight: 400;
  color: ${colors.textLight};
  line-height: 40px;
  border-bottom: 4px solid transparent;
  ${props => props.active && `border-bottom: 4px solid #2A55C3; font-weight: 600; color: ${colors.primary};`}
`

const tabIsApplicable = (tabName: string, action: Action | null) => {
  if (!action) {
    return false
  }

  switch (tabName) {
    case 'request':
      return Boolean(
        action.auth_type ||
          action.url ||
          action.request_type ||
          action.request_body ||
          action.request_headers ||
          action.request_parameters
      )
    case 'response':
      return Boolean(action.response_body || action.response_headers || action.endpoint_failed)
  }
}
