import { FileInputProps, FileInput as GrommetFileInput, ThemeContext } from 'grommet'
import styled, { css } from 'styled-components/macro'

export const FileInput = ({
  ...props
}: FileInputProps & {
  accept?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  inlineError?: boolean | string
}) => {
  const { inlineError } = props
  return (
    <>
      <ThemeContext.Extend
        value={{
          fileInput: {
            message: {
              color: 'text',
              textAlign: 'center'
            },
            background: 'transparent',
            border: { size: 'small', color: inlineError ? 'error' : undefined },
            pad: { horizontal: 'small', vertical: 'xsmall' },
            round: 'xxsmall',
            label: {
              size: 'large'
            },
            margin: { bottom: 'xxsmall' }
          }
        }}
      >
        <FileInputContainer>
          <StyledGrommetFileInput aria-label="File upload" {...props}></StyledGrommetFileInput>
        </FileInputContainer>
      </ThemeContext.Extend>
    </>
  )
}

const StyledGrommetFileInput = styled(GrommetFileInput)``

export const FileInputFocusCss = css`
  input[type='file']:focus-visible {
    // remove the global input focus styles
    box-shadow: none !important;
    // added specificity for Safari
    &[tabindex='0'] {
      opacity: 0 !important;
    }
    // grab the first sibling div of the Radiobox
    & {
      border: 5px solid red;
    }
  }
`

const FileInputContainer = styled.div`
  ${FileInputFocusCss}
`
