import { Simplify } from 'type-fest'

import { SavedFilter } from 'main/services/api/data-providers/runbook-types'
import {
  useGetAllSavedFilters,
  useGetAllSavedFiltersCallback,
  useGetBySavedFilter,
  useGetBySavedFilterCallback,
  useGetSavedFilter,
  useGetSavedFilterCallback,
  useGetSavedFilterLookup,
  useGetSavedFilterLookupCallback,
  useOnActionSavedFilter
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelSingleAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelInterfaceWithSingleAccessBy as WithSingleAccessBy
} from './types'

type SavedFilterSingleAccessBy = ModelSingleAccessBy<Pick<SavedFilter, 'default'>>

export type SavedFilterModelType = Simplify<
  CollectionModelInterfaceBase<SavedFilter> &
    WithSingleAccessBy<SavedFilter, SavedFilterSingleAccessBy> &
    Omit<WithActions<'Filter'>, 'useCanAction' | 'useCanActionCallback'>
>

export const SavedFilterModel: SavedFilterModelType = {
  useGet: useGetSavedFilter,
  useGetCallback: useGetSavedFilterCallback,

  useGetBy: useGetBySavedFilter,
  useGetByCallback: useGetBySavedFilterCallback,

  useGetAll: useGetAllSavedFilters,
  useGetAllCallback: useGetAllSavedFiltersCallback,

  useGetLookup: useGetSavedFilterLookup,
  useGetLookupCallback: useGetSavedFilterLookupCallback,

  useOnAction: useOnActionSavedFilter
}
