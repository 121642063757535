import { ReactNode } from 'react'
import { RequireAllOrNone } from 'type-fest'

import { ItemCreateInputProps } from '@cutover/react-ui'

// Filter Options
type FilterOptionBase = {
  label: string
  slug?: string
}

export type FilterOptionCheckbox = FilterOptionBase & {
  color?: string
  count?: number
  value: string | number
  options?: FilterOptionRadio[]
} & RequireAllOrNone<{ labelReversed: string; valueReversed: string | number }, 'labelReversed' | 'valueReversed'>

export type FilterOptionSelect = FilterOptionBase & { color?: string; count?: number; value: string }
export type FilterOptionDate = FilterOptionBase & { value?: Date }
export type FilterOptionText = FilterOptionBase & { value?: string }
export type FilterOptionRadio = { label: string; value: string }
export type FilterOptionHierarchy = FilterOptionBase & {
  suffix?: ReactNode
  value: string | number
  children?: FilterOptionHierarchy[]
  itemCreateInputProps?: ItemCreateInputProps
}

// Filter Groups
type FilterGroupBase<Option extends FilterOptionBase> = {
  canGroup?: boolean
  canSpotlight?: boolean
  customFieldId?: number | string
  footer?: ReactNode
  header?: ReactNode
  isInitiallyOpen?: boolean
  options: Option[]
  slug?: string
  title: string
  withAnyValueOption?: boolean
  withNoValueOption?: boolean
}

export type FilterGroupSelect = FilterGroupBase<FilterOptionSelect> & {
  type: 'select'
  expandable?: boolean
  optionDisplayLimit?: number
}
export type FilterGroupCheckbox = FilterGroupBase<FilterOptionCheckbox> & {
  type: 'checkbox'
  optionDisplayLimit?: number
}
export type FilterGroupDate = FilterGroupBase<FilterOptionDate> & { type: 'date' }
export type FilterGroupText = FilterGroupBase<FilterOptionText> & { type: 'text' }
export type FilterGroupRunbookId = Omit<FilterGroupBase<any>, 'options'> & {
  type: 'runbook-id'
  slug: 'runbook_id'
}
export type FilterGroupSearch = Omit<FilterGroupBase<any>, 'options'> & {
  type: 'search'
  slug: 'q'
}
export type FilterGroupHierarchy = FilterGroupBase<FilterOptionHierarchy> & {
  type: 'hierarchy'
  itemCreateInputProps?: ItemCreateInputProps
}

// Filter type unions
export type FilterGroup =
  | FilterGroupCheckbox
  | FilterGroupDate
  | FilterGroupSelect
  | FilterGroupText
  | FilterGroupSearch
  | FilterGroupRunbookId
  | FilterGroupHierarchy

export type FilterOption =
  | FilterOptionCheckbox
  | FilterOptionDate
  | FilterOptionSelect
  | FilterOptionText
  | FilterOptionHierarchy

export type SelectedFilter = string | number | Date | undefined | (string | number | Date | null)[]
export type SelectedFilters = {
  [key in number | string]: SelectedFilter | { [key in number | string]: SelectedFilter }
}

type FilterTypeDef = {
  array?: boolean
  type: 'int' | 'string' | 'json' | 'bool'
}

export type RunbooksFilter =
  | 'a'
  | 'alr'
  | 'archived'
  | 'approval_status'
  | 'approver'
  | 'awaiting_my_review'
  | 'dd'
  | 'de'
  | 'df'
  | 'dt'
  | 'event'
  | 'f'
  | 'fields'
  | 'is_type_incident'
  | 'is_type_snippet'
  | 'late'
  | 'lateness'
  | 'm'
  | 'p'
  | 'q'
  | 'project'
  | 'runbook_id'
  | 'run_type'
  | 'sort_by'
  | 'sort_dir'
  | 'stage'
  | 'status'
  | 'subFilterKey'
  | 'subFilterValue'
  | 't'
  | 'team'
  | 'template_type'
  | 'tpr'
  | 'ts'
  | 'type'

export const RUNBOOKS_FILTER_DEFS: Record<RunbooksFilter, FilterTypeDef> = {
  a: { type: 'bool' },
  alr: { type: 'bool' },
  archived: { type: 'bool' },
  approval_status: { type: 'string', array: true },
  approver: { type: 'int', array: true },
  awaiting_my_review: { type: 'bool' },
  dd: { type: 'string' },
  de: { type: 'string' },
  df: { type: 'int' },
  dt: { type: 'int' },
  event: { array: true, type: 'int' },
  f: { type: 'json' },
  fields: { array: true, type: 'string' },
  is_type_incident: { type: 'bool' },
  is_type_snippet: { type: 'bool' },
  late: { type: 'bool' },
  lateness: { array: true, type: 'string' },
  m: { type: 'bool' },
  p: { type: 'bool' },
  q: { type: 'string' }, // Why is this not in the angular defs?
  project: { array: true, type: 'int' },
  run_type: { array: true, type: 'string' },
  runbook_id: { array: true, type: 'int' },
  sort_by: { type: 'string' },
  sort_dir: { type: 'string' },
  stage: { array: true, type: 'string' },
  status: { array: true, type: 'string' },
  subFilterKey: { type: 'string' },
  subFilterValue: { type: 'string' },
  t: { type: 'bool' },
  team: { array: true, type: 'int' },
  template_type: { array: true, type: 'string' },
  tpr: { type: 'bool' },
  ts: { array: true, type: 'string' },
  type: { array: true, type: 'int' }
}

export type RunbookFilter =
  | 'a'
  | 'at'
  | 'c'
  | 'connected'
  | 'cp'
  | 'critical_to_here'
  | 'critical'
  | 'ct'
  | 'dd'
  | 'df'
  | 'dt'
  | 'er'
  | 'f'
  | 'fe'
  | 'fs'
  | 'he'
  | 'hi'
  | 'hp'
  | 'hs'
  | 'includeUsers'
  | 'l'
  | 'lv'
  | 'm'
  | 'mt'
  | 'or'
  | 'pathFrom'
  | 'pathTo'
  | 'predecessors_to_here'
  | 'rbc'
  | 'sn'
  | 'spotlight'
  | 'sr'
  | 'stage'
  | 'stream'
  | 'team'
  | 'type'
  | 'user'
  | 'x'
  | 'y'

// TODO: confirm overrides in keys are the same type...
export const RUNBOOK_FILTER_DEFS: Record<RunbookFilter, FilterTypeDef> = {
  // np: { type: 'bool'},  // commented out in angular defs
  // p: { array: true, type: 'int'}, // commented out in angular defs
  // pe: { type: 'bool'}, // commented out in angular defs
  a: { type: 'bool' },
  at: { type: 'bool' },
  c: { type: 'bool' },
  connected: { type: 'int' },
  cp: { type: 'json' },
  critical_to_here: { type: 'int' },
  critical: { type: 'bool' },
  ct: { array: true, type: 'string' },
  dd: { type: 'string' },
  df: { type: 'int' },
  dt: { type: 'int' },
  er: { array: true, type: 'string' },
  f: { type: 'json' },
  fe: { type: 'bool' },
  fs: { type: 'bool' },
  he: { type: 'bool' },
  hi: { type: 'bool' },
  hp: { type: 'bool' },
  hs: { type: 'bool' },
  includeUsers: { type: 'bool' },
  l: { type: 'bool' },
  lv: { array: true, type: 'string' },
  m: { type: 'bool' },
  mt: { type: 'bool' },
  or: { type: 'bool' },
  pathFrom: { type: 'int' },
  pathTo: { type: 'int' },
  predecessors_to_here: { type: 'int' },
  rbc: { array: true, type: 'int' },
  sn: { type: 'bool' },
  spotlight: { type: 'string' },
  sr: { array: true, type: 'string' },
  stage: { array: true, type: 'string' },
  stream: { array: true, type: 'int' },
  team: { array: true, type: 'int' },
  type: { array: true, type: 'int' },
  user: { array: true, type: 'int' },
  x: { type: 'string' },
  y: { type: 'string' }
}

export const FILTER_DEFS = { ...RUNBOOKS_FILTER_DEFS, ...RUNBOOK_FILTER_DEFS } as const

export type AllFilters = RunbookFilter | RunbooksFilter
