import { Box, Message, Text } from '@cutover/react-ui'
import { FormModal } from 'main/components/shared/form'
import { TaskLineItemWrapper } from 'main/components/shared/task-line-item'
import { useLanguage } from 'main/services/hooks'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'
import { useTaskListTask, useTaskProgression } from 'main/recoil/data-access'
import { TaskAbandonConfirmData } from 'main/recoil/runbook'
import { ActiveRunModel } from 'main/data-access'

type TaskConfirmAbandonProps = {
  id: number
  onClose: () => void
  data: TaskAbandonConfirmData
}

export const TaskAbandonConfirmModal = ({ id, onClose, data }: TaskConfirmAbandonProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskAbandonConfirmModal' })
  const task = useTaskListTask(id as number)
  const { startOrFinishTask } = useTaskProgression()
  const run = ActiveRunModel.useGet()
  const tasks = data.tasks

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      startOrFinishTask(id, {
        field_values_attributes: data.field_values,
        selected_successor_ids: data.selected_successor_ids
      })
    },
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  const showChildRunbookWarning = run?.run_type === 'live' && tasks.some(task => task.linked_resource)

  return (
    <FormModal
      data-testid="modal-task-abandon-confirm"
      title={t('title')}
      open
      animate="out"
      onClose={handleClose}
      confirmIcon={confirmIcon}
      onSubmit={handleSubmit}
      confirmText={confirmText}
      customErrors={stageChangedErrors}
    >
      {renderContent(
        <>
          {tasks.length > 0 ? (
            <>
              <Box gap="16px" margin={{ bottom: '12px' }}>
                <Text>{t('message', { count: tasks.length })}</Text>
                {showChildRunbookWarning && <Message type="warning" message={t('childRunbookWarning')} />}
              </Box>
              {tasks.map(task => (
                <TaskLineItemWrapper key={task.id} taskId={task.id} />
              ))}
            </>
          ) : (
            <Text>{t('noTasks')}</Text>
          )}
        </>
      )}
    </FormModal>
  )
}
