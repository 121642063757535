import { memo, useMemo } from 'react'
import { Heading } from 'grommet'
import { unescape } from 'lodash'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, IconButton, RunbookTypeIcon, TabGroup, TabItem } from '@cutover/react-ui'
import { RunbookControl } from './runbook-control'
import { RunbookElapsedTime } from './runbook-elapsed-time'
import { RunbookToolbar } from './runbook-toolbar'
import { DisplayType } from '../runbook-sub-header/view-navigation'
import { useAccountMetaProperty, useIsCurrentRunbookVersion } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { routes } from 'main/services/routing/routes'
import { ActiveAccountModel, ActiveRunbookModel, ConfigModel } from 'main/data-access'

type RunbookActiveTab = 'pages' | 'dashboards' | 'tasks'

export const RunbookHeader = () => {
  const navigate = useNavigate()
  const isCurrentRunbookVersion = useIsCurrentRunbookVersion()

  const { slug: accountId } = ActiveAccountModel.useGet()

  const { template_type: templateType, name } = ActiveRunbookModel.useGet()

  const runbookType = ActiveRunbookModel.useRunbookType()
  const isTemplate = templateType === 'default'

  return (
    <Box direction="row" fill justify="between" align="center">
      <Box direction="row" align="center" gap="6px">
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          {/* TODO: go back to the runbook list with the most recent set of filters applied if navigated to the runbook
          from the runbooks list */}
          <IconButton
            tertiary
            icon="arrow-back"
            label="back" // TODO: i18n
            onClick={() => navigate(`/app/${accountId}/runbooks/list`)}
          />
        </Box>
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          <RunbookTypeIcon
            icon={runbookType?.icon_name || 'getting-started'}
            color={runbookType?.icon_color || 'primary'}
            css="margin-right:8px;"
            template={isTemplate}
          />
        </Box>
        <Box justify="between" fill>
          <Box
            direction="row"
            gap="xxsmall"
            css={`
              position: relative;
              top: 5px;
            `}
            flex={false}
          >
            <RunbookTitle
              data-testid="runbook-title"
              responsive={false}
              margin={{ left: 'xsmall', right: 'none', vertical: 'none' }}
              level="1"
            >
              {unescape(name)}
            </RunbookTitle>
            <RunbookToolbar />
          </Box>
          <Box
            css={`
              white-space: nowrap;
              overflow-y: hidden;
              overflow-x: auto;
            `}
          >
            <Tabs accountId={accountId} runbookTypeId={runbookType.id} />
          </Box>
        </Box>
      </Box>
      {isCurrentRunbookVersion && (
        <Box direction="row" align="center" flex={false}>
          <Box pad={{ left: 'xsmall', right: 'xsmall' }}>
            <RunbookElapsedTime />
          </Box>
          <Box direction="row" margin={{ top: '4px', right: '-6px' }} css="flex-basis: 52px;" flex={false}>
            <RunbookControl />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const Tabs = memo(({ accountId, runbookTypeId }: { accountId: string; runbookTypeId: number }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook.tabs' })
  const isReactSrdEnabled = ConfigModel.useIsFeatureEnabled('react_srd')
  const navigate = useNavigate()
  const { runbookId, runbookVersionId } = useParams()

  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')
  const currentPage = displayMatch?.params.displayType as DisplayType
  const currentPageToTabsMap: Partial<Record<DisplayType, RunbookActiveTab>> = {
    'react-list': 'tasks',
    'react-dashboard': 'dashboards'
  }
  const activeTab = currentPageToTabsMap[currentPage] as RunbookActiveTab

  const dashboards = useAccountMetaProperty({ attribute: 'dashboards' })

  const reactDashboardMatch = useMatch(
    '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/react-dashboard/:dashboardId'
  )

  const dashboardId = reactDashboardMatch?.params.dashboardId

  const runbookDashboards = isReactSrdEnabled
    ? dashboards.filter(
        dashboard =>
          dashboard.context === 'Runbook' &&
          dashboard.dashboard_type === 'dashboard' &&
          dashboard.key !== 'pir' &&
          (dashboard.default ||
            !dashboard.display_on ||
            (runbookTypeId && JSON.parse(dashboard.display_on).includes(runbookTypeId)))
      )
    : []

  const runbookDashboardMenuItems = runbookDashboards.map(dashboard => ({
    label: dashboard.name,
    selected: dashboard.id === Number(dashboardId),
    onClick: () => {
      navigate(
        routes.toDashboard({
          accountSlug: accountId,
          runbookId: Number(runbookId),
          versionId: Number(runbookVersionId),
          dashboardId: dashboard.id,
          reactRunbook: true
        })
      )
    }
  }))

  const items = useMemo(() => {
    return [
      // Disabled Pages as only in Angular for now
      // {
      //   label: t('pages'),
      //   active: activeTab === 'pages',
      //   onClick: () => {}
      // },
      isReactSrdEnabled &&
        !!runbookDashboards.length && {
          label: t('dashboards'),
          active: activeTab === 'dashboards',
          menuItems: runbookDashboardMenuItems
        },
      {
        label: t('tasks'),
        active: activeTab === 'tasks',
        onClick: () => {
          navigate(
            routes.toRunbook({
              accountSlug: accountId,
              runbookId: Number(runbookId),
              versionId: Number(runbookVersionId),
              reactRunbook: true
            })
          )
        }
      }
    ].filter(Boolean) as TabItem[]
  }, [activeTab, dashboardId])

  return <TabGroup items={items} />
})

const RunbookTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 5px;
`
