import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import * as yup from 'yup'

import { toSnakeCase } from '@cutover/api'
import { useNotify } from '@cutover/react-ui'
import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { dataSourceFormValidationSchema } from '../data-sources-form-validation-schema'
import { DataSourcesForm } from '../data-sources-form'
import { FormModal } from 'main/components/shared/form'
import { FormRenderProps } from 'main/components/shared/form/form'
import { useCurrentUser } from 'main/recoil/data-access'
import { ApiError } from 'main/services/api'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { isJsonString } from 'main/services/json-utils'
import { DataSource } from 'main/services/queries/types'
import {
  TestResultsResponse,
  useCreateDataSourceMutation,
  useTestDataSourceMutation,
  useUpdateDataSourceMutation
} from 'main/services/queries/use-data-sources'
import { ConfigModel } from 'main/data-access'

type CreateNewDataSourceModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export type CreateDataSourceModal = DataSource & {
  _step?: number
}

const formType = dataSourceFormValidationSchema()
export type CreateDataSourceSchema = yup.InferType<typeof formType>

// To have these fields validated remove them from this array.
export const settingFieldsToRemove = [
  'webhookUrl',
  'successMappings',
  'responseXmlToJson',
  'responseMode',
  'responseHandling',
  'outboundPayload',
  'outboundParameters',
  'outboundHeaders',
  'errorMappings',
  'requestType'
]

const DOBOUNCE_MILIS = 150

export const CreateNewDataSourceModal = ({ open, setOpen }: CreateNewDataSourceModalType) => {
  const notify = useNotify()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useLanguage('dataSources')
  const { mutate } = useCreateDataSourceMutation()
  const updateMutation = useUpdateDataSourceMutation()
  const testMutation = useTestDataSourceMutation()
  const [testResults, setTestResults] = useState<TestResultsResponse | undefined>(undefined)
  const [testResultsError, setTestResultsError] = useState<string | undefined>(undefined)
  const [hasOauthSession, setHasOauthSession] = useState<boolean | undefined>(false)
  const [customErrors, setCustomErrors] = useState<string[] | undefined>()
  const { listen } = useUserWebsocket()
  const { requestClientSettings } = ConfigModel.useGet()
  const currentUser = useCurrentUser()

  const defaultValues: Partial<CreateDataSourceSchema> = {
    _step: 1,
    id: null,
    name: '',
    url: '',
    http_method: 'get',
    http_headers: '{}',
    json_root: '',
    json_mappings: '[]'
  }

  const handleClose = () => {
    setOpen(false)
    setTestResults(undefined)
    setTestResultsError(undefined)
    setIsLoading(false)
  }

  const handleCompletion = async (data: CreateDataSourceModal) => {
    data.json_mappings = JSON.parse(data.json_mappings)
    data.temporary = false
    updateMutation.mutate(toSnakeCase(data) as CamelToSnakeCaseNested<DataSource>, {
      onSuccess: () => {
        setIsLoading(false)
        setOpen(false)
        setCustomErrors(undefined)
        notify.success(t('dataSources.modals.updateDataSource.toasters.success'))
      },
      onError: error => {
        setIsLoading(false)
        notify.error(t('dataSources.modals.updateDataSource.toasters.genericError'))
        const castedError = error as unknown as ApiError
        setCustomErrors([castedError?.errors.join(', ')])
      }
    })
  }

  const submitTest = async (data: CreateDataSourceModal) => {
    setTestResults(undefined)
    setTestResultsError(undefined)

    testMutation.mutate(toSnakeCase(data), {
      onSuccess: response => {
        if (!response) {
          return
        }

        const testResponse = response
        // Edge-case: checking if result is parsable, as some API return a simple string
        if (response.body && isJsonString(response.body)) {
          testResponse.body = JSON.parse(response.body)
        }

        setHasOauthSession(data.settings?.authType === 'OAuth')
        setIsLoading(false)
        setTestResults(testResponse ?? {})
      },
      onError: error => {
        // Work might still be required for handling errors response (hard to reproduce)
        setIsLoading(false)
        setTestResultsError(error.toString())
      }
    })
  }

  const handleClickConfirm = async (formContext: FormRenderProps<any>) => {
    const { trigger, getValues, setValue } = formContext
    const currentStep = getValues('_step')
    const isValid = await trigger()
    if (isValid) {
      setCustomErrors([])
      const data = toSnakeCase(getValues()) as CamelToSnakeCaseNested<DataSource>
      if (data.id) {
        // Update existing datasource anytime back button is clicked.
        updateMutation.mutate(data, {
          onSuccess: response => {
            // Redirect user to do OAuth Authentication data source has none
            if (!hasOauthSession) {
              const dataSourceId = response?.data_source?.id as number
              openOauthAuthenticationWindow(dataSourceId, data.settings)
            }
            notify.success(t('dataSources.modals.updateDataSource.toasters.success'))
          },
          onError: error => {
            const castedError = error as unknown as ApiError
            setCustomErrors([castedError?.errors.join(', ')])
            setIsLoading(false)
            notify.error(t('dataSources.modals.updateDataSource.toasters.genericError'))
          }
        })
      } else {
        data.temporary = true
        delete data.id
        // Create the datasource and update the Form id prop with the new datasource ID
        mutate(data, {
          onSuccess: response => {
            const dataSourceId = response?.data_source?.id as number
            setValue('id', dataSourceId)
            notify.success(t('dataSources.modals.createDataSource.toasters.success'))
            openOauthAuthenticationWindow(dataSourceId, data.settings)
          },
          onError: error => {
            setValue('_step', currentStep)
            const castedError = error as unknown as ApiError
            setCustomErrors([castedError?.errors.join(', ')])
            setIsLoading(false)
            notify.error(t('dataSources.modals.createDataSource.toasters.genericError'))
          }
        })
      }
    }
  }

  const openOauthAuthenticationWindow = (dataSourceId: number, settings: any) => {
    if (settings?.auth_type === 'OAuth' && settings.oauth_flow === 'Authorization Code') {
      const url = `/api/data_sources/oauth/authentications/authorize?location=${encodeURIComponent(
        location.href
      )}&data_source_id=${dataSourceId}&user_id=${currentUser.id}`
      window.open(url, '_blank')
    }
  }

  const handleSubmitTest = (formData: CreateDataSourceModal, query: { query_string: string }) => {
    const data = { ...formData, query: query }
    submitTest(data)
  }

  const unsetLoading = debounce(
    () => {
      setIsLoading(false)
    },
    DOBOUNCE_MILIS,
    { leading: true }
  )

  useEffect(() => {
    const handleAsyncTestResponse = (data: TestResultsResponse) => {
      const testResponse = data
      setIsLoading(false)
      setTestResults(testResponse)
      unsetLoading()
    }

    listen(data => {
      handleAsyncTestResponse(data as TestResultsResponse)
    })
  }, [])

  return (
    <FormModal<CreateDataSourceSchema, CreateDataSourceModal>
      schema={dataSourceFormValidationSchema(requestClientSettings)}
      title={step => {
        return step === 1
          ? t('dataSources.modals.createDataSource.title')
          : t('dataSources.modals.createDataSource.test.title')
      }}
      confirmText={step => {
        return step === 1
          ? t('dataSources.modals.createDataSource.confirmationText.next')
          : t('dataSources.modals.createDataSource.confirmationText.create')
      }}
      onClickConfirm={handleClickConfirm}
      confirmIcon={'add'}
      steps={2}
      open={open}
      onClose={handleClose}
      onSubmit={handleCompletion}
      defaultValues={defaultValues}
      preventAutoClose={true}
      customErrors={customErrors}
    >
      <DataSourcesForm
        isLoading={isLoading}
        testResults={testResults}
        testResultsError={testResultsError}
        setTestResults={setTestResults}
        setTestResultsError={setTestResultsError}
        setIsLoading={setIsLoading}
        handleSubmitTest={handleSubmitTest}
      />
    </FormModal>
  )
}
