import { useContext } from 'react'

import { WorkspaceDataContext } from './workspace-data-provider'

export const useWorkspaceData = () => {
  return useContext(WorkspaceDataContext)
}

export const useRunbooksMetaProjects = () => {
  const workspaceData = useWorkspaceData()

  if (!workspaceData) {
    throw new Error('You are not currently nested within a WorkspaceDataContext.')
  }

  const { projects, projectLookup } = workspaceData

  return { projects, projectLookup }
}

export const useWorkspaceMeta = () => {
  const { runbooksMeta, centralTeamsMeta, runbooksMetaFull } = useWorkspaceData()
  return { runbooksMeta, centralTeamsMeta, runbooksMetaFull }
}
