import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { MyCutoverPermissionsType } from '../queries/types'
import { useCurrentUser } from 'main/recoil/data-access'

export const useHasPermissionsOrNavigateHome = (scope: MyCutoverPermissionsType) => {
  const currentUser = useCurrentUser()

  const navigate = useNavigate()
  const userCanViewPage = currentUser.permissions.views.mycutover[scope]

  useEffect(() => {
    if (!userCanViewPage) {
      navigate('/app/my-cutover')
    }
  }, [userCanViewPage])

  return userCanViewPage
}
