import { memo, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { eventManager } from 'event-manager'

import { Box, Button, LoadingPanel, useInitialMount } from '@cutover/react-ui'
import { usePostAppEvents } from './apps-api'
import { getAvailableAppsWithUserSettings, saveAvailableAppsInUserSettings } from './available-apps-settings'
import { AppsComponent } from './apps-component'
import { AppsComponentParser } from './apps-component-parser'
import { AppsManagementModal } from './apps-management-modal'
import { AppsModal } from './apps-modal'
import { AppView, AppViews, ContentNode, SelectedApp } from './apps-types'
import { PanelNode as Panel } from './nodes'
import { useLanguage } from 'main/services/hooks'
import {
  useAppHeaderViewValue,
  useAppsModalState,
  useAppViewWithContextValue,
  useRunbookAppViewsWithOrderValue,
  useSetAppViews
} from 'main/recoil/data-access'

export const AppsContainer = ({ appPosition = 'panel' }: { appPosition?: string }) => {
  const { t } = useLanguage('apps')
  const { runbookId } = useParams()
  const postAppEvents = usePostAppEvents()
  const isInitialMount = useInitialMount()
  const setAppViews = useSetAppViews()
  const orderedAppViews = useRunbookAppViewsWithOrderValue(runbookId as string)
  const headerAppView = useAppHeaderViewValue()
  const [availableApps, setAvailableApps] = useState<SelectedApp[] | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const [modal, setModal] = useAppsModalState()

  useEffect(() => {
    const handleRunbookData = ({
      runbook
    }: {
      permissions: { [x: string]: number[] }
      runbook: { is_template: boolean }
    }) => {
      setIsTemplate(runbook.is_template)
    }
    eventManager.on('runbook-data', handleRunbookData)

    return () => {
      eventManager.off('runbook-data', handleRunbookData)
    }
  }, [])

  useEffect(() => {
    // fresh load of page, remove any previous modals
    if (isInitialMount) setModal({ view: undefined, appId: '', resourceId: '', open: false })
    if (runbookId && !isTemplate && appPosition === 'panel') {
      getAvailableAppsWithUserSettings(runbookId, postAppEvents).then((apps: SelectedApp[]) => {
        // Avoid unnecessary update to state
        if (apps.length > 0) {
          setAvailableApps(apps)
        }
      })
    }
  }, [runbookId, isTemplate])

  useEffect(() => {
    if (availableApps) {
      handleAvailableAppsChange()
    }
  }, [availableApps])

  const handleAvailableAppsChange = () => {
    setAppViews(prevAppViews => {
      const updatedAppViews: AppViews = {}
      availableApps?.forEach(selectedApp => {
        const context = `${runbookId}-${selectedApp.appId}`
        const prevAppView = prevAppViews[context]

        // Previously loaded so just update visibility
        if (prevAppView) {
          const updatedAppView = { ...prevAppView, visible: selectedApp.visible, order: selectedApp.order }
          updatedAppViews[context] = updatedAppView
          // Init this app
        } else if (selectedApp.visible) {
          updatedAppViews[context] = {
            appId: selectedApp.appId,
            resourceId: selectedApp.resourceId,
            content: [],
            visible: selectedApp.visible,
            order: selectedApp.order
          }

          postAppEvents({
            runbook_id: runbookId,
            app_id: selectedApp.appId
          })
        }
      })
      return { ...prevAppViews, ...updatedAppViews }
    })
  }

  return (
    <>
      {appPosition === 'panel' && (
        <Box
          fill
          flex={false}
          background="bg-1"
          css={`
            height: 'calc(100vh - 72px)';
            overflow: auto;
            padding: 16px 0;
          `}
        >
          <Box
            css={`
              height: 100%;
            `}
          >
            <Box
              flex={false}
              gap="medium"
              css={`
                margin-bottom: 16px;
              `}
            >
              {orderedAppViews.map(
                app =>
                  app.visible && (
                    <AppsContainerInner key={`app-id-${app.appId}`} appId={app.appId} resourceId={app.resourceId} />
                  )
              )}
            </Box>
            <AppsModal modal={modal} />
            <AppsManagementModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onClickConfirm={(availableApps: SelectedApp[] | undefined) => {
                setAvailableApps(availableApps)
                saveAvailableAppsInUserSettings(runbookId, availableApps)
              }}
              availableApps={availableApps}
            />
            <Button
              icon="add"
              label={t('manageApps')}
              secondary
              onClick={() => {
                setIsOpen(true)
              }}
              full
              css={`
                margin-top: auto;
              `}
            />
          </Box>
        </Box>
      )}
      {appPosition === 'header' && (
        <Box style={{ height: '100%' }} flex={false} gap="medium" data-apps-view-id={headerAppView.view?.id}>
          <AppsComponentParser
            content={headerAppView.view?.content as ContentNode[]}
            appId={headerAppView.appId}
            resourceId={runbookId}
          />
        </Box>
      )}
    </>
  )
}

type AppsContainerInnerProps = {
  appId: string
  resourceId?: string
}

export const AppsContainerInner = memo(({ appId, resourceId }: AppsContainerInnerProps) => {
  const context = `${resourceId}-${appId}`
  const view = useAppViewWithContextValue(context)
  if (!view) {
    return null
  }
  const appLoading = isEmpty(view.content)
  const viewContent = view.content && (
    <AppsComponentParser content={view.content as ContentNode[]} appId={appId} resourceId={resourceId} />
  )

  return (
    <Box
      css={`
        display: ${view?.visible ? 'block' : 'none'};
      `}
    >
      {appLoading ? (
        <Panel header={{ title: '' }} appId={appId} resourceId={resourceId}>
          <LoadingPanel />
        </Panel>
      ) : (
        <Box data-apps-view-id={view.id}>
          <AppsComponent node={view as AppView} appId={appId} resourceId={resourceId}>
            {viewContent}
          </AppsComponent>
        </Box>
      )}
    </Box>
  )
})
