import { createGlobalStyle, css } from 'styled-components/macro'

import { CSSDimensionConstraints, themeColor, themeEdgeSize, themeElevation, ThemeType } from './'

export const GlobalStyles = ({ enableFocusRings = false }: { enableFocusRings?: boolean }) => {
  return (
    <>
      <BaseGlobalStyles />
      {enableFocusRings && <FocusRingGlobalStyles />}
    </>
  )
}

const BaseGlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    background-color: white;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  * {
    -webkit-font-smoothing: antialiased;
  }
`

/*
  The :focus-visible pseudo-class allows for differentiation of mouse and keyboard users.
  In general, :focus-visible styles will only be applied for keyboard users (text inputs should always indicate some focus).

  Browsers that don't support :focus-visible will fallback to browser defaults

  Remove the focus indicator on mouse-focus for browsers that do support :focus-visible
*/
export const FocusRingGlobalStyles = createGlobalStyle`
  @supports selector(:focus-visible) {
    *[tabindex="0"],
    *[tabindex="-1"],
    button,
    input,
    select,
    textarea,
    area,
    a {
      &:focus {
        outline: none;
      }
    }
  }
  /* Draw a noticeable focus ring for keyboard-focus on browsers that do support :focus-visible */
  *[tabindex="0"],
  *[tabindex="-1"],
  button,
  select,
  textarea,
  area,
  a {
    &:focus-visible,
    // the go- are angular directives that can be removed once angular is gone.
    &[go-checkbox].md-focused .md-icon,
    &[go-radio-group].md-focused .md-checked .md-off {
      transition: box-shadow .1s ease-in-out !important;
      outline: none;
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${({ theme }) => themeColor('primary')({ theme })} !important;
      opacity: 1 !important;
      z-index: 1 !important;
    }
  }
  /* Remove ring from first programatically focused element in modals */
  *[aria-modal="true"] a[tabindex="-1"][aria-hidden="true"]:first-of-type {
    box-shadow: none !important;
  }
  /* Customize :focus-visible ring on click in text inputs */
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  input[type="text"][tabindex],
  textarea,
  textarea[tabindex],
  *[contenteditable],
  // the go- are angular directives that can be removed once angular is gone.
  [go-checkbox],
  [go-radio-group],
  .no-focus {
    &:focus-visible {
      outline: none !important;
      box-shadow: unset !important;
    }
  }
`

export const focusRingCss = css<ThemeType>`
  transition: box-shadow 0.1s ease-in-out !important;
  outline: none;
  box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${({ theme }) => themeColor('primary')({ theme })} !important;
  opacity: 1 !important;
  z-index: 1 !important;
`

export const focusRingInsetCss = css<ThemeType>`
  transition: box-shadow 0.1s ease-in-out !important;
  outline: none;
  box-shadow: inset 0px 0px 0px 2px ${({ theme }) => themeColor('primary')({ theme })} !important;
  opacity: 1 !important;
  z-index: 1 !important;
`

export const focusRingOutlineCss = css`
  // NOTE: transition 'all' is needed for safari. 'border-radius 0s' prevents animating from square border shape
  transition: all 0.1s ease-in-out, border-radius 0s !important;
  // workaround for safari not showing focus rings until repaint
  transform: translate(0, 0) translate3d(0, 0, 0) !important;
  outline: 2px solid ${({ theme }) => themeColor('primary')({ theme })} !important;
  outline-offset: 2px !important;
`

export const overlayStyles = (constraints?: CSSDimensionConstraints) => css`
  border-radius: 8px;
  user-select: none;
  padding: ${themeEdgeSize('xsmall')};
  box-shadow: ${themeElevation('medium')};
  background-color: ${themeColor('menu-bg')};
  min-width: ${constraints?.minWidth};
  max-width: ${constraints?.maxWidth};
  min-height: ${constraints?.minHeight};
  max-height: ${constraints?.maxHeight};
`
