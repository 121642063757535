import { Fragment, ReactNode, useState } from 'react'
import { isEqual } from 'lodash'

import { PeopleListItemLayout } from './people-list-item-layout'
import { ItemSize } from './size-config'
import { Avatar } from '../../../avatar'
import { Button } from '../../../button'
import { Menu, MenuListItem } from '../../../menu'

export type UserItemStatus =
  | 'archived'
  | 'external'
  | 'not_allowed'
  | 'invited'
  | 'active'
  | 'external'
  | 'not_in_account'

export type UserListItemSelectedRole = {
  role: 'Admin' | 'Team Member' | 'Stream Editor'
  streamIds?: number[]
}

export type CreatedUserListItemSelectedRole = UserListItemSelectedRole & { id: number }

export type UserItemAvailableRole = {
  role: 'Admin' | 'Team Member' | 'Stream Editor'
  streams?: UserItemStream[]
}

type UserItemStream = {
  id: number
  name?: string
}

// NOTE: putting specifics in here like role menu seems a bit opinionated
// to be in react UI but it removes a lot of repetition

export type UserListItemProps = {
  id: number
  name?: string // sometimes API already concats the fname/lname
  firstName?: string
  lastName?: string
  handle?: string
  color: string
  online?: boolean | null
  size?: ItemSize
  status?: UserItemStatus
  onClick?: () => void
  onClickRemove?: (id: number) => void
  disabled?: boolean
  subtitle?: string
  titleSuffix?: string
  draggable?: boolean
  endComponents?: ReactNode[]
  active?: boolean
  hasError?: boolean
  role?: UserListItemSelectedRole
  onRoleSelect?: (item: UserListItemSelectedRole & { id: number }) => void
  availableRoles?: UserItemAvailableRole[]
  'data-testid'?: string
  a11yTitle?: string
}

export const UserListItem = ({
  id,
  name,
  firstName,
  lastName,
  handle,
  color,
  online,
  size,
  onClick,
  onClickRemove,
  status = 'active',
  draggable = true,
  disabled,
  subtitle,
  titleSuffix,
  endComponents,
  active,
  hasError,
  role,
  onRoleSelect,
  availableRoles,
  a11yTitle,
  'data-testid': testId
}: UserListItemProps) => {
  const [showStreams, setShowStreams] = useState<boolean>(role?.role === 'Stream Editor')
  const [selectedStreamIds, setSelectedStreamIds] = useState<number[]>(role?.streamIds ?? [])

  // Note: state of the role item is managed internally too since multiple users can have
  // their role changed before the form is actually submitted.
  const [selectedRoleItem, setSelectedRoleItem] = useState(role)

  const userStatusLabel = getUserStatusLabel(status)
  // Note - recipients can be users not in cutover, so we dont have a name. In this case the handle contains the response
  const fullName = name ? name : firstName && lastName ? firstName + ' ' + lastName : handle
  const suffix = titleSuffix ? titleSuffix : userStatusLabel ? '(' + userStatusLabel + ')' : ''
  const isAdmin = !!(selectedRoleItem && selectedRoleItem.role === 'Admin')
  const isDisabled = disabled || status === 'archived' || status === 'not_in_account'
  // Note: a linked team contains a disabled user select, since new users cant be added. BUT existing users can still be dragged
  const disableDrag = status === 'archived' || status === 'not_in_account'

  const getRoleButtonLabel = () => {
    return (
      selectedRoleItem?.role +
      (selectedRoleItem?.role === 'Stream Editor' && Number(selectedRoleItem?.streamIds?.length) > 1
        ? ' (' + selectedRoleItem?.streamIds?.length + ')'
        : '')
    )
  }

  const roleMenu =
    role &&
    ((!isDisabled && availableRoles && availableRoles.length > 1 && onRoleSelect ? (
      <Menu
        align="end"
        trigger={
          <Button
            secondary
            icon="caret-down"
            reverse
            onClick={event => event.stopPropagation()}
            label={getRoleButtonLabel()}
            size="small"
            customStyle={isAdmin ? undefined : 'secondary-alt'}
          />
        }
        onMenuChange={event => {
          if (!event.open && selectedRoleItem?.role === 'Stream Editor' && !isEqual(selectedRoleItem, role)) {
            // For the stream editor role, we want to save the changes when the menu closes,
            // instead of on menuItem click (for the Admin & Team Member options). This is because
            // you can select multiple streams. Could maybe change this so onRoleSelect is called
            // with every checkbox check but would need to deal with the re-rendering
            onRoleSelect?.({ id, ...selectedRoleItem })
          }
        }}
      >
        {availableRoles.map((availableRole, i) => {
          return (
            <Fragment key={`role-${availableRole.role}-${i}`}>
              <MenuListItem
                label={availableRole.role}
                value={availableRole.role}
                selected={availableRole.role === selectedRoleItem?.role}
                expandable={availableRole.role === 'Stream Editor'}
                expanded={showStreams}
                onClick={event => {
                  event.syntheticEvent.stopPropagation()
                  const clickedRole = { role: event.value } as UserListItemSelectedRole
                  if (clickedRole.role === 'Stream Editor') {
                    //This acts as a toggle so want to keep menu open & show streams
                    event.keepOpen = true
                    setShowStreams(!showStreams)
                  } else {
                    onRoleSelect?.({ id, ...clickedRole })
                    setSelectedRoleItem(clickedRole)
                  }
                }}
              />
              {showStreams &&
                availableRole.streams?.map((stream, i) => {
                  return (
                    <MenuListItem
                      key={`stream-${stream.id}-${i}`}
                      label={stream.name ? stream.name : 'stream-' + stream.id}
                      value={stream.id}
                      type="checkbox"
                      checked={selectedStreamIds.includes(stream.id)}
                      selected={selectedStreamIds.includes(stream.id)}
                      onClick={event => {
                        event.keepOpen = true
                        event.syntheticEvent.stopPropagation()
                        const clickedStreamId = event.value

                        setSelectedStreamIds(prevIds => {
                          let newStreamIds = [] as number[]
                          if (prevIds.includes(clickedStreamId)) {
                            newStreamIds = prevIds.filter(id => id !== clickedStreamId)
                          } else {
                            newStreamIds = [...prevIds, clickedStreamId]
                          }

                          const newRole = { role: 'Stream Editor' } as UserListItemSelectedRole
                          if (newStreamIds.length > 0) {
                            newRole.streamIds = newStreamIds
                          }
                          setSelectedRoleItem(newRole)

                          return newStreamIds
                        })
                      }}
                    />
                  )
                })}
            </Fragment>
          )
        })}
      </Menu>
    ) : (
      <Button
        secondary
        label={getRoleButtonLabel()}
        size="small"
        customStyle={isAdmin ? undefined : 'secondary-alt'}
        disabled
        css={'opacity: 1 !important;'}
      />
    )) as ReactNode)

  const rightContent = endComponents ? [roleMenu, ...endComponents] : ([roleMenu] as ReactNode[] | undefined)

  return (
    <PeopleListItemLayout
      id={id}
      title={fullName ?? ''}
      subtitle={subtitle}
      titleSuffix={suffix}
      size={size}
      onClick={onClick}
      active={active}
      hasError={hasError}
      onClickRemove={onClickRemove}
      prominence={userStatusLabel || isDisabled ? 'low' : isAdmin ? 'high' : 'default'}
      //disabled={isDisabled}
      avatar={
        <Avatar
          subject={{ id, first_name: firstName, last_name: lastName, name, online, color }}
          draggable={!disableDrag && draggable}
          size={size}
          data-testid="user-item-avatar"
        />
      }
      endComponents={rightContent}
      data-testid={testId}
      a11yTitle={a11yTitle ?? fullName}
    />
  )
}

const getUserStatusLabel = (status: UserItemStatus | undefined | null) => {
  if (status == null) {
    return undefined
  }

  const statusLabels: Record<UserItemStatus, string | undefined> = {
    archived: 'Archived',
    not_in_account: 'Not in workspace',
    invited: 'Invited',
    active: undefined,
    external: undefined,
    not_allowed: undefined
  }

  return statusLabels[status]
}
