import { memo, useMemo } from 'react'

import { TaskTypeIconName } from '@cutover/icons'
import { ColorProp, IconName, TaskItemIcon } from '@cutover/react-ui'
import { RunbookStage, RunbookVersion, TaskListTask } from 'main/services/queries/types'

type TaskListWidgetIconProps = {
  taskTypeIcon?: TaskTypeIconName
  color?: ColorProp | string
  stage?: TaskListTask['stage']
  successorIds?: number[]
  predecessorIds?: number[]
  isNew?: boolean
  onClick?: any
  skipped?: boolean
  abandoned?: boolean
  previousTaskSuccessorIds?: number[] // Note: only used when inserting new task between 2 other connected tasks
  previousTask?: TaskListTask
  nextTask?: TaskListTask
  canAction?: boolean
  muteComms?: boolean
  isLoading?: boolean
  taskItemIsHovered?: boolean
  runbookStage?: RunbookStage
  newTaskLoading?: boolean
  runbookVersionRun?: RunbookVersion['run']
  canEdit?: boolean
  previousTaskId?: number
  nextTaskId?: number
  currentTaskStartDisplay?: number | null
  hasFixedStart?: boolean
}

/**
 * Used by TaskListWidget dashboard component which passes props instead of obtaining state from recoil.
 * See TaskListIcon for a recoil connected version that used in the task list. The two should be merged.
 */
export const TaskListWidgetIcon = memo(
  ({
    stage = 'default',
    taskTypeIcon = 'normal',
    color = 'primary',
    predecessorIds,
    previousTask,
    nextTask,
    isNew = false,
    onClick,
    skipped,
    abandoned,
    previousTaskSuccessorIds,
    muteComms,
    isLoading,
    runbookStage,
    newTaskLoading,
    runbookVersionRun,
    canEdit,
    currentTaskStartDisplay,
    hasFixedStart
  }: TaskListWidgetIconProps) => {
    const hasTaskConnectorUp = isNew || (previousTask?.id && predecessorIds?.includes(previousTask?.id))
    const hasNewTaskConnectorDown =
      isNew && previousTask?.id && nextTask?.id && previousTaskSuccessorIds?.includes(nextTask?.id)

    const iconName = useMemo(() => {
      const iconType = (taskTypeIcon || '').split('_')[1] || 'normal'
      let icon: string

      if (stage === 'complete') {
        icon = `${iconType}-100`
      } else if (isNew) {
        icon = `${iconType}-dashed`
      } else {
        icon = iconType
      }

      return icon
    }, [taskTypeIcon])

    // TODO: update as needed - as part of task progression work
    const fixedStartTimeInFuture =
      hasFixedStart && !!currentTaskStartDisplay && currentTaskStartDisplay * 1000 > Date.now()

    const displayFixedStartClockIcon =
      hasFixedStart &&
      (runbookStage === 'active' || runbookStage === 'paused') &&
      runbookVersionRun?.run_type === 'live'

    // NOTE - need to revisit these 'hover icons' as well as permission modifiers when we start task list
    //const innerIcon = isNew ? 'close' : 'add'

    const canAddTask = canEdit && (isNew || newTaskLoading || runbookStage === 'planning' || runbookStage === 'paused')
    const getStageIcon = (
      stage: TaskListTask['stage'],
      skipped: boolean,
      abandoned: boolean,
      isNew: boolean
    ): IconName | undefined => {
      if (displayFixedStartClockIcon) return 'fixed-start'
      if (canAddTask) return 'add'
      if (isNew) return undefined
      if (skipped) return 'skipped'
      if (abandoned) return 'abandoned'
      switch (stage) {
        case 'startable':
          return 'play-arrow'
        case 'in-progress':
          return 'more-horizontal' // this differs from the main task list which shows a dashed check icon
        case 'complete':
          return 'check-solid'
        case 'default':
          return undefined // we don't show an icon when not startable
      }
    }

    const stageIcon = getStageIcon(stage, !!skipped, !!abandoned, !!isNew)

    return (
      <TaskItemIcon
        isLoading={isLoading}
        onClick={onClick}
        icon={iconName as TaskTypeIconName}
        color={color as string}
        stageIcon={stageIcon}
        disableNotify={muteComms}
        withConnectors={
          hasTaskConnectorUp
            ? {
                previousIcon: 'normal',
                dotted: fixedStartTimeInFuture,
                nextIcon: hasNewTaskConnectorDown ? 'normal' : undefined
              }
            : undefined
        }
      />
    )
  }
)
