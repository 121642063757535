import { Box, Modal, Text, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useTaskListTask } from 'main/recoil/data-access'
import {
  INTEGRATION_FINISHED_STATUSES,
  IntegrationFinishedStatus,
  IntegrationStatus
} from 'main/services/queries/types'
import { cancelIntegration } from 'main/services/queries/use-task'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'

type TaskIntegrationAbortModalProps = {
  taskId: number
  name: string
  open: boolean
  onClose: () => void
}

export const TaskIntegrationAbortModal = ({ taskId, name, open, onClose }: TaskIntegrationAbortModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskIntegrationAbortModal' })
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const task = useTaskListTask(taskId)
  const { name: taskName, integration_events } = task
  const notify = useNotify()
  const setIntegrationRequest = RunbookViewModel.useOnAction('integrationRequest:add')

  /**
   * Note: The backend assumes a single Cancelled event is sufficient to detect cancellation.
   * However, active polling requests in separate threads may cause the integration status to be incorrectly
   * marked as 'Running', causing the 'last_event' to incorrectly suggest the integration should continue running.
   *
   * This may lead to an incorrect computation of the integration status in the frontend if the 'cancel' event
   * triggered by the user in the abort modal overlaps a polling request.
   *
   * Refer to the backend `cancelled?` method in `integration_action.rb` for more details.
   */
  const handleConfirm = async () => {
    const lastIntegrationEvent = integration_events.length > 0 && integration_events[task.integration_events.length - 1]
    const eventStatus: IntegrationStatus = lastIntegrationEvent.status?.toLowerCase()
    const hasFinished = INTEGRATION_FINISHED_STATUSES.includes(eventStatus as IntegrationFinishedStatus)

    if (hasFinished) {
      onClose()
      notify.warning(t('unableToAbortMessage'), { title: t('unableToAbortTitle') })
    } else {
      setIntegrationRequest({ taskId, type: 'cancel' })
      await cancelIntegration({ runbookId, runbookVersionId, taskId })
      onClose()
    }
  }

  return (
    <Modal
      open={open}
      title={t('title')}
      onClose={onClose}
      confirmText={t('confirmText')}
      hasCancelButton={true}
      onClickConfirm={handleConfirm}
    >
      <Box>
        <Text weight="bold">{t('integrationName', { name })}</Text>
        <Text weight="bold">{t('taskName', { name: taskName })}</Text>
        <Text margin={{ top: 'medium', bottom: 'medium' }}>{t('content')}</Text>
        <Text margin={{ bottom: 'small' }} weight="bold">
          {t('disclaimer')}
        </Text>
      </Box>
    </Modal>
  )
}
