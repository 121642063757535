import styled, { keyframes } from 'styled-components'

import { LegacyBox as Box, Icon, LegacyBoxProps } from '@cutover/react-ui'

type ProgressItemProps = LegacyBoxProps & {
  stage?: 'complete' | 'in-progress'
  color?: string
}

export const StreamSummaryProgressBar = ({ stream }: any) => {
  return (
    <ProgressWrap>
      <ProgressWrapBg color={stream.color}></ProgressWrapBg>
      <ProgressItem
        bgColor={stream.color}
        width={(stream.totalCompletedTasks / stream.tasks_count) * 100 + '%'}
      ></ProgressItem>
      <ProgressItem
        className="in-progress"
        bgColor={stream.color}
        width={(stream.totalInProgressTasks / stream.tasks_count) * 100 + '%'}
      ></ProgressItem>
      {stream.completion === 100 || stream.totalCompletedTasks === stream.tasks_count ? (
        <CompleteText>
          <CompleteIcon icon="check" color="text-on-primary" css="margin-top: -2px;" />
          COMPLETE
        </CompleteText>
      ) : (
        <ProgressText color={stream.color}>{`${stream.totalCompletedTasks} / ${stream.tasks_count}`}</ProgressText>
      )}
    </ProgressWrap>
  )
}

const stripeAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 25px 25px;
  }
`

const ProgressWrap = styled(Box)`
  position: relative;
  height: 19px;
  border-radius: 10px;
  overflow: hidden;
`

const CompleteText = styled(ProgressWrap)`
  color: #fff;
  width: 100%;
  font-size: 14px;
  text-align: center;
  position: absolute;
  /* display: none; */
  line-height: 19px;
`
const CompleteIcon = styled(Icon)`
  line-height: 19px;
  font-size: 16px;
  width: 16px;
  margin-right: 2px;
  vertical-align: top;
`

const ProgressWrapBg = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${props => props.color};
  opacity: 0.4;
  position: absolute;
`

const ProgressText = styled(Box)`
  color: #fff;
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 19px;
  font-size: 14px;
  text-shadow: 0px 0px 5px ${props => props.color};
`
const ProgressItem = styled(Box)<ProgressItemProps>`
  float: left;
  height: 19px;
  box-sizing: border-box;
  background-color: ${props => props.bgColor};
  width: ${props => props.width};
  &.in-progress {
    background-size: 25px 25px;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    animation: ${stripeAnimation} 2s linear infinite;
  }
`
