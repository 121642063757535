import { memo, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { keyBy, unescape } from 'lodash'

import { Box, IconButton, ListItem, Pill, useNotify } from '@cutover/react-ui'
import { useCurrentUserId } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { SavedFilter } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel, SavedFilterModel } from 'main/data-access'
import { useDeleteFilter, useSetFilterDefault, useToggleFilterGlobal } from 'main/services/queries/use-filters'
import { parseSearchString } from '../shared/util'
import { buildRecoilSyncURI, encodeReservedCharacters } from 'main/recoil/shared/recoil-sync/recoil-sync-component'
import { getSavedViewQueryString } from 'main/components/shared/filter/filter-params'

export const SavedFiltersGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const savedFilters = SavedFilterModel.useGetAll()
  const defaultFilter = SavedFilterModel.useGetBy({ default: true })

  return savedFilters?.length ? (
    <FilterAccordionPanel label={t('title')}>
      <SavedFilterGroupList filters={savedFilters} defaultId={defaultFilter?.id} />
    </FilterAccordionPanel>
  ) : null
})

/* Group content - saved filter list */

const SavedFilterGroupList = ({ filters, defaultId }: { filters: SavedFilter[]; defaultId?: number }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const notify = useNotify()
  const savedFilterLookup = SavedFilterModel.useGetLookup()
  const filterLookup = keyBy(filters, 'id')
  const orderedIds = filters.map(f => f.id)
  const navigate = useNavigate()
  // @ts-ignore - location state isn't typed which makes this rename not work
  const { state: navigationState, pathname } = useLocation()
  const [replaceDefaultModalOpen, setReplaceDefaultModalOpen] = useState(false)
  const [changeToDefaultFilterId, setChangeToDefaultFilterId] = useState<number | null>(null)

  const initialActiveId = navigationState?.navDefault ? defaultId ?? null : null
  const stateActiveFilterId = navigationState?.activeFilterId ?? null
  const activeFilterId = navigationState?.navDefault ? initialActiveId : stateActiveFilterId

  const currentUserId = useCurrentUserId()
  const canUpdate = ActiveRunbookModel.useCan('update')
  const deleteFilter = useDeleteFilter().mutateAsync
  const toggleGlobalFilter = useToggleFilterGlobal().mutateAsync
  const setDefaultFilter = useSetFilterDefault().mutateAsync

  const processDestroyFilterResponse = SavedFilterModel.useOnAction('destroy')
  const processSetDefaultFilterResponse = SavedFilterModel.useOnAction('set_as_default')
  const processToggleGlobalFilterResponse = SavedFilterModel.useOnAction('toggle_global')

  const handleClickDelete = useCallback(
    async (filterId: number) => {
      await deleteFilter(filterId, {
        onSuccess: data => {
          processDestroyFilterResponse(data)
          notify.success(t('deleteMessage', { context: 'success' }))
        },
        onError: () => {
          notify.error(t('deleteMessage', { context: 'error' }))
        }
      })
    },
    [deleteFilter, processDestroyFilterResponse]
  )

  const handleClickToggleGlobal = useCallback(
    async (filterId: number) => {
      const response = await toggleGlobalFilter(filterId)
      processToggleGlobalFilterResponse(response)
    },
    [toggleGlobalFilter, processToggleGlobalFilterResponse]
  )

  const onClickDefault = useCallback(
    async (filterId: number) => {
      if (defaultId && defaultId !== filterId) {
        setChangeToDefaultFilterId(filterId)
        setReplaceDefaultModalOpen(true)
      } else {
        const response = await setDefaultFilter(filterId)
        processSetDefaultFilterResponse(response)
      }
    },
    [
      defaultId,
      processSetDefaultFilterResponse,
      setDefaultFilter,
      setChangeToDefaultFilterId,
      setReplaceDefaultModalOpen
    ]
  )

  const handleClickApplyFilter = useCallback(
    async (filterId: number) => {
      const filter = savedFilterLookup[filterId]
      const parsedSearch = parseSearchString(filter?.query_string ?? '')
      if (parsedSearch.q) {
        parsedSearch.q = encodeReservedCharacters(parsedSearch.q as string)
      }
      const searchString = getSavedViewQueryString(parsedSearch)
      const search = buildRecoilSyncURI(searchString)

      navigate({ pathname, search }, { state: { activeFilterId: filter?.id, hasActiveFilter: true } })
    },
    [
      pathname,
      navigate,
      savedFilterLookup,
      parseSearchString,
      encodeReservedCharacters,
      getSavedViewQueryString,
      buildRecoilSyncURI
    ]
  )

  return (
    <Box role="list" a11yTitle={t('listName')}>
      {orderedIds.map(id => {
        const filter = filterLookup[id]

        return (
          <SavedFilterGroupListItem
            key={id}
            filterId={filter.id}
            name={unescape(filter.name)}
            isSelected={filter.id === activeFilterId}
            isDefault={filter.id === defaultId}
            isGlobal={canUpdate ? filter.global : null}
            onClickFilter={handleClickApplyFilter}
            onClickSetDefault={canUpdate ? onClickDefault : undefined}
            onClickSetGlobal={canUpdate ? handleClickToggleGlobal : undefined}
            onClickDelete={canUpdate || filter.user_id === currentUserId ? handleClickDelete : undefined}
          />
        )
      })}

      {replaceDefaultModalOpen && (
        <ReplaceSavedFilterDefaultModal
          closeModal={() => {
            setReplaceDefaultModalOpen(false)
            setChangeToDefaultFilterId(null)
          }}
          onClickSave={async () => {
            if (changeToDefaultFilterId) {
              const response = await setDefaultFilter(changeToDefaultFilterId)
              processSetDefaultFilterResponse(response)
            }
            setReplaceDefaultModalOpen(false)
          }}
        />
      )}
    </Box>
  )
}

const ReplaceSavedFilterDefaultModal = ({
  closeModal,
  onClickSave
}: {
  closeModal: () => void
  onClickSave: () => Promise<void>
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.confirmReplaceDefaultModal' })

  // Using form modal because it handles async
  return (
    <FormModal
      formElementWrapper={false}
      description={t('message')}
      title={t('title')}
      onClose={closeModal}
      focusConfirmButton
      open
      confirmText={t('confirm')}
      onSubmit={async () => {
        await onClickSave()
      }}
    />
  )
}

/* Individual saved filter item */

type SavedFilterGroupListItemProps = {
  filterId: number
  name: string
  isSelected: boolean | null
  isDefault: boolean | null
  isGlobal: boolean | null
  onClickFilter: (filterId: number) => void
  onClickDelete?: (filterId: number) => void
  onClickSetDefault?: (filterId: number) => void
  onClickSetGlobal?: (filterId: number) => void
}

const SavedFilterGroupListItem = memo(
  ({
    filterId,
    name,
    isSelected,
    isDefault,
    isGlobal,
    onClickFilter,
    onClickDelete,
    onClickSetDefault,
    onClickSetGlobal
  }: SavedFilterGroupListItemProps) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.actionButtonLabels' })

    const isGlobalDefault = isGlobal && isDefault

    return (
      <ListItem
        role="listitem"
        a11yTitle={name}
        endComponents={[
          ...(isDefault ? [<Pill label={t('defaultPill')} color="text-light" size="small" />] : []),
          ...(onClickSetDefault
            ? [
                <IconButton
                  label={t('default', { context: isDefault ? 'unset' : 'set' })}
                  tipPlacement="top"
                  icon={isDefault ? 'pin-filled' : 'pin'}
                  onClick={e => {
                    e.stopPropagation()
                    onClickSetDefault(filterId)
                  }}
                  size="small"
                />
              ]
            : []),
          ...(onClickSetGlobal
            ? [
                <IconButton
                  css={`
                    &:hover {
                      background: ${isGlobalDefault && 'transparent'};
                      cursor: ${isGlobalDefault && 'default'};
                    }
                  `}
                  label={t('global', { context: isGlobalDefault ? 'default' : isGlobal ? 'private' : 'public' })}
                  tipPlacement="top"
                  icon={isGlobal ? 'star-filled' : 'star'}
                  onClick={e => {
                    e.stopPropagation()

                    if (!isGlobalDefault) {
                      onClickSetGlobal(filterId)
                    }
                  }}
                  size="small"
                />
              ]
            : [])
        ]}
        onClick={() => onClickFilter(filterId)}
        size="small"
        active={!!isSelected}
        prominence={isSelected ? 'high' : undefined} // Hack until design change as above, should use 'selected' prop
        title={name}
        onClickRemove={onClickDelete ? () => onClickDelete(filterId) : undefined}
        onClickRemoveTooltip={t('remove')}
        onClickRemoveTooltipPlacement="top"
      />
    )
  }
)
