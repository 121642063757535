import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { useForm } from 'react-hook-form'

import { toCamelCase } from '@cutover/api'
import { EditPanel, IconButton, LoadingPanel, useNotify } from '@cutover/react-ui'
import { DeleteUserModal } from './delete-user-modal'
import { UserForm } from './user-form'
import { Role, RunbookUserFormType, Stream } from '../types'
import { RunbookTeamUser } from '../use-runbook-team'
import {
  RunbookUser,
  RunbookUserTeam,
  useRunbookUser,
  useRunbookUserDelete,
  useRunbookUserUpdate
} from '../use-runbook-user'
import { RunbookPersonDeleteResponse } from 'main/services/api/data-providers/runbook-types'
import { useCurrentUser } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useIsOnReactRunbook } from 'main/services/routing'
import { useProcessRunbookPersonDeleteResponse } from 'main/recoil/data-access/updaters__TEMPORARY/runbook-person-operations'

type UserDetailsProps = {
  runbookId: number
  runbookVersionId: number
  userId: number
  streams: Stream[]
  onClose: () => void
  onBack: () => void
  templateType?: string
}

export function UserDetails({
  runbookId,
  runbookVersionId,
  userId,
  streams,
  onClose,
  onBack,
  templateType
}: UserDetailsProps) {
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState<boolean>(false)
  const [forceReRenderKey, setForceReRenderKey] = useState<number>(0)
  const [user, setUser] = useState<RunbookUser | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const currentUser = toCamelCase(useCurrentUser())
  const { t } = useLanguage()
  const processRunbookPersonDeleteResponse = useProcessRunbookPersonDeleteResponse()
  const isOnReactRunbook = useIsOnReactRunbook()

  const { data, isLoading, isSuccess } = useRunbookUser({
    currentUserId: currentUser?.id,
    runbookId,
    runbookVersionId,
    userId
  })

  const mutationUpdate = useRunbookUserUpdate({
    runbookId,
    runbookVersionId,
    userId
  })

  const mutationDelete = useRunbookUserDelete({
    runbookId,
    runbookVersionId,
    userId
  })

  const methods = useForm<RunbookUserFormType>({
    defaultValues: {
      id: userId,
      role: undefined,
      streams: [],
      teams: []
    }
  })

  const notify = useNotify()

  useEffect(() => {
    if (data) {
      setUser(data)
      methods.reset({
        role: data.role,
        streams: data.streamIds ?? [],
        teams: data?.teams
      })
    }
  }, [methods.reset, data])

  const onFormSubmit = async ({ role, streams, teams }: RunbookUserFormType) => {
    const leftTeamIds = teams.map(team => team.id)
    const removedRunbookTeamIds =
      data?.teams.reduce((removedIds: number[], team) => {
        if (!leftTeamIds.includes(team.id)) {
          removedIds.push(team.id)
        }
        return removedIds
      }, []) || []

    await mutationUpdate.mutateAsync({
      role: role.toLowerCase(),
      streamIds: (streams ?? []).map(stream => Number(stream)),
      removedRunbookTeamIds: removedRunbookTeamIds
    })

    if (JSON.stringify(user?.streamIds) !== JSON.stringify(streams && streams.map(stream => Number(stream)))) {
      notify.success(t('runbook:peoplePanel:users:userRolesUpdated'))
    }

    methods.reset({ role, streams, teams: teams })
  }

  useEffect(() => {
    if (mutationUpdate.isError) {
      setErrorMessage(mutationUpdate.error?.errors[0])
    }
  }, [mutationUpdate.isError])

  const openReassignModal = () => {
    // TODO: reassignment will not work when react_runbook feature flag is on
    if (isOnReactRunbook) {
      openUserDeleteModal()
    } else {
      eventManager.emit('open-delete-user-reassign-modal', {
        removedUser: user as RunbookTeamUser,
        continueUpdate: continueUpdate
      })
    }
  }

  const continueUpdate = async (data?: { usersToReassign: RunbookTeamUser[] }, reassignProps?: {}) => {
    if (data && data.usersToReassign.length == 0) {
      openUserDeleteModal()
    } else {
      await mutationDelete.mutateAsync(
        {
          reassign: reassignProps,
          data: data
        },
        {
          onSuccess: (response: RunbookPersonDeleteResponse) => {
            onBack()
            if (isOnReactRunbook) {
              processRunbookPersonDeleteResponse(response)
            }
            notify.success(t('runbook:peoplePanel:users:userWasDeleted', { userName: user?.name }))
          },
          onError: error => {
            setErrorMessage(error?.errors[0])
          }
        }
      )
    }
  }

  const onNonAssignedUserDelete = async () => {
    mutationDelete.mutate(
      {},
      {
        onSuccess: (response: RunbookPersonDeleteResponse) => {
          if (isOnReactRunbook) {
            processRunbookPersonDeleteResponse(response)
          }
          onBack()
          notify.success(t('runbook:peoplePanel:users:userWasDeleted', { userName: user?.name }))
        },
        onError: error => {
          setErrorMessage(error?.errors[0])
        }
      }
    )
  }

  const closeUserDeleteModal = () => {
    setIsDeleteUserModalOpen(false)
  }

  const openUserDeleteModal = () => {
    setIsDeleteUserModalOpen(true)
  }

  const onReset = () => {
    methods.reset()
    // Due to the way the 'form' is constructed, this is the only way to get 'discard' working without
    // a ton of extra complexity
    setForceReRenderKey(forceReRenderKey + 1)
  }

  const roleValue = methods.watch('role')
  const streamValues = methods.watch('streams')

  useEffect(() => {
    if (user?.isLastAdmin && roleValue !== Role.RunbookAdmin) {
      setErrorMessage(t('runbook:peoplePanel:users:atLeastOneAdmin'))
    } else if (roleValue === Role.StreamEditor && (!streamValues || streamValues.length === 0)) {
      setErrorMessage(t('runbook:peoplePanel:users:noStreamIds', { userName: user?.name }))
    } else if (errorMessage) {
      setErrorMessage(null)
    }
  }, [roleValue, streamValues])

  return (
    <EditPanel
      title={t('runbook:peoplePanel:users:heading')}
      isDirty={methods.formState.isDirty}
      onBack={onBack}
      onClose={onClose}
      headerItems={[
        ...(methods.formState.isDirty || !user?.canUpdate
          ? []
          : [
              <HeaderContent
                openReassignModal={openReassignModal}
                setWarningMessage={setErrorMessage}
                teams={data && data.teams}
                user={user}
              />
            ])
      ]}
      isSubmitting={methods.formState.isSubmitting}
      onSubmit={methods.handleSubmit(onFormSubmit)}
      onReset={onReset}
    >
      <DeleteUserModal
        isOpen={isDeleteUserModalOpen}
        closeModal={closeUserDeleteModal}
        userName={user?.name}
        onDelete={onNonAssignedUserDelete}
        isDeleting={mutationDelete.isLoading}
      />
      {isLoading || !isSuccess ? (
        <LoadingPanel />
      ) : user ? (
        <UserForm
          key={forceReRenderKey}
          user={user}
          allStreams={streams || []}
          methods={methods}
          isDisabled={!data}
          errorMessage={errorMessage}
          openReassignModal={openReassignModal}
          templateType={templateType}
        />
      ) : null}
    </EditPanel>
  )
}

type HeaderProps = {
  setWarningMessage: (value: string | null) => void
  teams: RunbookUserTeam[] | undefined
  user: RunbookUser & { isLastAdmin?: boolean }
  openReassignModal: () => void
}

function HeaderContent({ setWarningMessage, teams, user, openReassignModal }: HeaderProps) {
  const { t } = useLanguage()

  const isUserInLinkedTeam = () => teams && teams.some(team => team.linked)

  const openDeleteModal = () => {
    if (isUserInLinkedTeam()) {
      setWarningMessage(t('runbook:peoplePanel:users:userIsInLinkedTeam'))
    } else if (user.isLastAdmin) {
      setWarningMessage(t('runbook:peoplePanel:users:userIsLastAdmin'))
    } else {
      setWarningMessage(null)
      openReassignModal()
    }
  }

  return (
    <IconButton
      tertiary
      data-testid="delete-user-button"
      icon="trash-o"
      onClick={openDeleteModal}
      label={t('common:deleteUserButton')}
    />
  )
}
