import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useLayoutEffect,
  useState
} from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FieldValues,
  FormProvider,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormProps,
  UseFormReset,
  UseFormResetField,
  UseFormReturn,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form'
import * as yup from 'yup'
import { usePrevious } from 'react-use'
import { AxiosError } from 'axios'

import { Form as FormElement, useNotify } from '@cutover/react-ui'
import type { FieldContextType } from './form-fields'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'

export const DEFAULT_RESET_OPTIONS = {
  keepIsSubmitted: true,
  keepDirty: false,
  keepSubmitCount: true
}

export type FormRenderProps<TFieldValues extends FieldValues> = UseFormReturn<TFieldValues> & {
  errorMessage?: null | string[]
  onSubmit: (data: TFieldValues) => any
}

export type FormProps<TFieldValues extends FieldValues, TApiValues extends Record<string, any> = TFieldValues> = {
  children?: (ReactElement | null | boolean | ReactElement[])[] | ((props: FormRenderProps<TFieldValues>) => ReactNode)
  onSubmit?: (data: TApiValues) => any
  // TODO: type this
  onSuccess?: (resp: any) => void
  onError?: (e?: any) => void
  transformer?: (data: TFieldValues) => TApiValues
  schema?: yup.ObjectSchema<TFieldValues>
  readOnly?: boolean
  disabled?: boolean
  successMessage?: string
  errorMessage?: string
  resetOptions?: Parameters<UseFormReset<TFieldValues>>[1]
  formElementWrapper?: boolean
  ref?: RefObject<FormType<TFieldValues>>
} & UseFormProps<TFieldValues, FieldContextType<TFieldValues>>

export type FormType<TFieldValues extends FieldValues> = {
  submit: (e?: any) => Promise<void>
  reset: UseFormReset<TFieldValues>
  resetField: UseFormResetField<TFieldValues>
  formState: FormState<TFieldValues>
  validate: UseFormTrigger<TFieldValues>
  getValues: UseFormGetValues<TFieldValues>
  clearErrors: UseFormClearErrors<TFieldValues>
  setValue: UseFormSetValue<TFieldValues>
}

const FormComponent = <TFieldValues extends FieldValues, TApiValues extends Record<string, any> = TFieldValues>(
  {
    children,
    onSubmit,
    schema,
    transformer,
    disabled,
    readOnly,
    defaultValues,
    onSuccess,
    successMessage,
    errorMessage: defaultErrorMessage,
    onError,
    resetOptions = DEFAULT_RESET_OPTIONS,
    formElementWrapper = true,
    ...useFormProps
  }: FormProps<TFieldValues, TApiValues>,
  ref?: ForwardedRef<FormType<TFieldValues>>
) => {
  const { t } = useLanguage('common')
  const notify = useNotify()
  const [errorMessage, setErrorMessage] = useState<null | string[]>(null)

  const formProps = schema
    ? {
        resolver: yupResolver(schema),
        defaultValues,
        context: {
          disabled,
          readOnly,
          schema
        },
        ...useFormProps
      }
    : { ...useFormProps }

  const methods = useForm<TFieldValues, FieldContextType<TFieldValues>>({ ...formProps })

  const { reset, resetField, handleSubmit: submitForm, trigger, formState, setValue } = methods

  const { errors, touchedFields, isSubmitSuccessful } = formState

  const errorCount = Object.keys(errors).length

  useLayoutEffect(() => {
    if (errorCount > 0) {
      setErrorMessage([t('formInvalid')])
    } else {
      setErrorMessage(null)
    }
  }, [errorCount])

  const hasTouchedFields = !!Object.keys(touchedFields).length
  const hasPreviousTouchedFields = usePrevious(hasTouchedFields)

  useLayoutEffect(() => {
    if (isSubmitSuccessful && hasTouchedFields && !hasPreviousTouchedFields) {
      setErrorMessage(null)
    }
  }, [hasTouchedFields])

  const handleSubmit = async ({ _step, ...data }: TFieldValues & { _step?: number }) => {
    try {
      const apiData = (transformer ? transformer(data as TFieldValues) : data) as unknown as TApiValues
      const response = await onSubmit?.(apiData)
      // clear any api errors from previous submission
      setErrorMessage(null)
      onSuccess?.(response)
      successMessage && notify.success(successMessage)
      // Keep updated form values after submitting.
      // The reason this is undefined is related to form fields that are reset imperatively.
      reset(undefined, { keepValues: true, ...resetOptions })
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorMessage(e.errors?.length ? e.errors : [defaultErrorMessage ?? e.message])
      } else if (e instanceof AxiosError) {
        const errors = e.response?.data?.errors
        setErrorMessage(errors.length ? errors : [defaultErrorMessage ?? e.message])
      }
      onError?.(e)
      // Keep form values after failure and also keep the form in dirty state so
      // user can try to submit again as the form is still dirty or discard.
      reset(undefined, { ...resetOptions, keepValues: true, keepDirty: true, keepErrors: true, keepTouched: false })
    }
  }

  const clearErrors: (typeof methods)['clearErrors'] = path => {
    setErrorMessage(null)
    return methods.clearErrors(path)
  }

  const resetForm: (typeof methods)['reset'] = (values, options) => {
    clearErrors()
    reset(values, options)
  }

  useImperativeHandle(ref, () => ({
    submit: () => submitForm?.(handleSubmit)(),
    reset: resetForm,
    resetField,
    validate: trigger,
    getValues: methods.getValues,
    formState,
    clearErrors,
    setValue
  }))

  const childContent =
    typeof children === 'function' ? (
      children({
        ...methods,
        errorMessage,
        onSubmit: handleSubmit,
        reset: resetForm
      })
    ) : (
      <>{children}</>
    )

  return (
    <FormProvider {...methods}>
      {formElementWrapper ? (
        <FormElement onSubmit={e => e.preventDefault()} css="height: 100%">
          {childContent}
        </FormElement>
      ) : (
        childContent
      )}
    </FormProvider>
  )
}

export const Form = forwardRef(FormComponent) as typeof FormComponent
