import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { TaskTypeIconName } from '@cutover/icons'
import { Box, duration as formatDuration, Icon, Pill, LegacyTableBase as Table, Text } from '@cutover/react-ui'
import {
  computeDashboardTaskCustomFieldLabels,
  TaskToCustomFieldLabelData,
  useDashboardTaskCustomFieldLabels
} from './use-task-custom-field-labels'
import { getTaskListData, TaskListRowData, useTaskListData } from './use-task-list-data'
import { filterTaskData, useFilterTaskData } from '../../../runbook-dashboard/use-task-filtering'
import { MrdDashboardWidget } from '../../../widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType, DashboardStageType } from '../../types'
import { TaskListWidgetIcon as TaskListIcon } from 'main/components/runbook/pages/task-list/task-list-widget-icon'
import { TaskType } from 'main/services/queries/types'

export type TaskListWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

/**
 * Displays A list of filtered tasks
 */
export const TaskListWidget = ({ media, data }: TaskListWidgetProps) => {
  return media === 'email' ? <EmailWidget data={data} /> : <BrowserWidget data={data} />
}

const EmailWidget = ({ data }: { data: DashboardComponent }) => {
  const { timezone } = data
  const { filters, task_types, stage_types, streams } = data
  const filteredTasks = filterTaskData(data)
  const title = `${getPrefixFromLookup(filters, task_types, stage_types)} tasks list`
  const { isAutoFinish, rows, isMore } = getTaskListData({
    tasks: filteredTasks,
    taskTypes: task_types,
    streams,
    filters,
    limit: 10
  })
  const customFieldLabels = computeDashboardTaskCustomFieldLabels(filteredTasks, data.custom_fields, data.users)

  return (
    <MrdDashboardWidget title={title}>
      <WidgetContent
        isAutoFinish={isAutoFinish}
        rows={rows}
        customFieldLabels={customFieldLabels}
        isMore={isMore}
        timezone={timezone}
      />
    </MrdDashboardWidget>
  )
}

const BrowserWidget = ({ data }: { data: DashboardComponent }) => {
  const { timezone } = data
  const { filters, task_types, stage_types, streams } = data
  const filteredTasks = useFilterTaskData(data)
  const title = `${getPrefixFromLookup(filters, task_types, stage_types)} tasks list`
  const { isAutoFinish, rows, isMore } = useTaskListData({
    tasks: filteredTasks,
    taskTypes: task_types,
    streams,
    filters
  })
  const customFieldLabels = useDashboardTaskCustomFieldLabels(filteredTasks, data.custom_fields, data.users)

  return (
    <MrdDashboardWidget title={title} fullWidth>
      <WidgetContent
        isAutoFinish={isAutoFinish}
        rows={rows}
        customFieldLabels={customFieldLabels}
        isMore={isMore}
        timezone={timezone}
      />
    </MrdDashboardWidget>
  )
}

const WidgetContent = ({
  isAutoFinish,
  rows,
  customFieldLabels,
  isMore,
  timezone
}: {
  isAutoFinish: boolean
  rows: TaskListRowData[]
  customFieldLabels: TaskToCustomFieldLabelData
  isMore: boolean
  timezone: string
}) => {
  return (
    <>
      <Table width="100%">
        <Table.Head>
          <Table.Row>
            <Table.Header width={isAutoFinish ? '80%' : '45%'}>Title</Table.Header>
            {!isAutoFinish && <Table.Header width="20%">Start</Table.Header>}
            <Table.Header width="20%">Finish</Table.Header>
            {!isAutoFinish && <Table.Header width="15%">Duration</Table.Header>}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {rows.length === 0 && (
            <Table.Row>
              <Table.Cell width="100%">
                <Text css="text-align: center">No tasks found</Text>
              </Table.Cell>
            </Table.Row>
          )}

          {rows.map(row => (
            <Table.Row key={row.id}>
              <Table.Cell width={isAutoFinish ? '80%' : '45%'}>
                {/* flexbox 'gap' isn't support in ie11... but since we're doing away with that in 2 months, I dont think this is a problem here. ie11 folks would just see no
               space between the icon and the name...
               */}
                <Box direction="row" gap="6px" align="center">
                  {/* Note - this is forcing legacy row data to work with new task-list-icon */}
                  <TaskListIcon
                    stage={row.stage}
                    taskTypeIcon={row.title.icon as TaskTypeIconName}
                    color={row.title.color}
                    skipped={row.skipped}
                    abandoned={row.abandoned}
                  />
                  <Box css="flex-grow:1;">
                    <Box wrap css="margin-bottom: 4px">
                      <Text data-testid={`task-list-widget-${row.id}-title`}>{row.title.text}</Text>
                      {customFieldLabels[row.id].map(label => (
                        <Box
                          key={`${row.id}-${label.text}`}
                          margin="2px"
                          data-testid={`task-list-widget-${row.id}-custom-field`}
                        >
                          <Pill size="small" color={label.backgroundColor} label={label.text} />
                        </Box>
                      ))}
                    </Box>
                    <Text size="13px" data-testid={`task-list-widget-${row.id}-subtitle`}>
                      {row.title.subText}
                    </Text>
                  </Box>

                  {/* TODO: Add ability to click when embedded into browser dashboard */}
                  {row.title.hasComments && (
                    <Box margin={{ left: 'auto' }}>
                      <Icon icon="message" color="text-light" />
                    </Box>
                  )}
                </Box>
              </Table.Cell>
              {!isAutoFinish && (
                <Table.Cell width="20%">
                  {row.start && (
                    <>
                      <Text
                        css={`
                          opacity: ${row.start.started ? 1 : 0.5};
                        `}
                      >
                        {formatDate(row.start.value, timezone)}
                      </Text>
                      {row.start.diff && (
                        <Diff
                          value={formatDuration(row.start.diff.value)}
                          type={row.start.diff.early ? 'early' : 'late'}
                        />
                      )}
                    </>
                  )}
                </Table.Cell>
              )}
              <Table.Cell width="20%">
                <Text
                  css={`
                    opacity: ${row.finish.finished ? 1 : 0.5};
                  `}
                >
                  {formatDate(row.finish.value, timezone)}
                </Text>

                {row.finish.diff && (
                  <Diff value={formatDuration(row.finish.diff.value)} type={row.finish.diff.early ? 'early' : 'late'} />
                )}
              </Table.Cell>
              {!isAutoFinish && (
                <Table.Cell width="15%">
                  {row.duration && formatDuration(row.duration.value)}
                  {row.duration && row.duration.diff && (
                    <Diff
                      value={formatDuration(row.duration.diff.value)}
                      type={row.duration.diff.early ? 'early' : 'late'}
                    />
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {isMore && (
        <Box justify="center" margin={{ top: '16px' }}>
          {/* TODO: Add click event for browser */}
          <Text>Show more…</Text>
        </Box>
      )}
    </>
  )
}

const getPrefixFromLookup = (
  filter: DashboardComponent['filters'],
  taskTypes: TaskType[],
  stageTypes: DashboardStageType[]
): string => {
  if (filter?.task_type_id) {
    const [selectedTaskType] = taskTypes.filter(taskType => taskType.id === filter.task_type_id)
    return selectedTaskType?.name
  }

  const [selectedStage] = stageTypes.filter(stageType => stageType.id === filter?.stage)
  return selectedStage?.name
}

// TODO: extract this into react-ui component. we need this in lots of places and can reuse in the metric tiles (used in the task completion summary widget)
const Diff = ({ value, type }: { value?: string; type?: 'early' | 'late' }) => {
  return (
    <Text color={type && type === 'early' ? 'success' : 'warning'}>
      {value && type ? (type === 'early' ? '-' : '+') : '\u00A0'}
      {value}
    </Text>
  )
}

const formatDate = (ts: number, timezone: string): string => {
  const utcDate = new Date(ts * 1000)
  const dateInZone = utcToZonedTime(utcDate, timezone)
  return format(dateInZone, 'd MMM HH:mm')
}
