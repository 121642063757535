import { createContext, ReactNode, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { UserChannelResponse } from '../data-providers/user/user-channel-response-types'
import { useCurrentUser } from 'main/recoil/data-access'
import { useWebsockets } from 'main/services/hooks'

const USER_CHANNEL_NAME = 'UserChannel'

export const UserChannelContext = createContext<UserChannelResponse | undefined>(undefined)

export const UserChannelProvider = ({ children }: { children: ReactNode }) => {
  const websockets = useWebsockets()
  const user = useCurrentUser()
  const subscriptionId = user.id

  const [userChannelResponse, setUserChannelResponse] = useState<UserChannelResponse>()

  const userChannelSubscription = websockets.findExistingSubscription(USER_CHANNEL_NAME, subscriptionId)

  useMount(() => {
    setUserChannelResponse({} as UserChannelResponse)
    if (!userChannelSubscription) {
      websockets.subscribe(USER_CHANNEL_NAME, subscriptionId, {
        received: (data: UserChannelResponse) => {
          setUserChannelResponse(data)
        }
      })
    }
  })

  useUnmount(() => {
    userChannelSubscription?.unsubscribe()
  })

  return <UserChannelContext.Provider value={userChannelResponse}>{children}</UserChannelContext.Provider>
}
