import { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import { Box, Checkbox, RadioboxGroup, Text } from '@cutover/react-ui'
import { FormModal } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { TaskListTask } from 'main/services/queries/types'
import { useTaskListTask } from 'main/recoil/data-access'

type TaskSuccessorsModalProps = {
  open: boolean
  onClose: () => void
  onSubmit: (successors: number[]) => void
  previousTask: TaskListTask
}

type SuccessorsOptions = 'series' | 'parallel' | 'pick'

export const TaskSuccessorsModal = ({ open, onClose, onSubmit, previousTask }: TaskSuccessorsModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskSuccessorsModal' })
  const [selectedOption, setSelectedOption] = useState<SuccessorsOptions>('series')
  const [selectedSuccessors, setSelectedSuccessors] = useState<number[]>([...previousTask.successor_ids])

  const radioboxOptions = [
    {
      label: t('inSeries'),
      value: 'series',
      autoFocus: true
    },
    {
      label: t('inParallel'),
      value: 'parallel'
    },
    {
      label: t('illPick'),
      value: 'pick'
    }
  ]

  useEffect(() => {
    handleSuccessorOptionSelection('series')
  }, [open])

  const handleSuccessorSelection = (id: number) => {
    const selected = [...selectedSuccessors]
    selected.includes(id) ? selected.splice(selected.indexOf(id), 1) : selected.push(id)
    setSelectedSuccessors(selected)
  }

  const handleSuccessorOptionSelection = (option: SuccessorsOptions) => {
    setSelectedOption(option)
    switch (option) {
      case 'series':
        setSelectedSuccessors([...previousTask.successor_ids])
        break
      case 'parallel':
      case 'pick':
        setSelectedSuccessors([])
        break
    }
  }

  return (
    <FormModal
      title={t('title')}
      open={open}
      onClose={onClose}
      confirmIcon="chevron-right"
      confirmText={t('confirmText')}
      formElementWrapper={false}
      onSubmit={() => onSubmit(selectedSuccessors)}
    >
      <Text>
        {parse(t('content', { taskName: previousTask.name, numSuccessors: previousTask.successor_ids.length }))}
      </Text>
      <RadioboxGroup
        name="successors"
        value={selectedOption}
        options={radioboxOptions}
        onChange={e => handleSuccessorOptionSelection(e.target.value as SuccessorsOptions)}
      />
      {selectedOption === 'pick' && (
        <Box style={{ marginLeft: '24px' }}>
          {previousTask.successor_ids.map(id => (
            <TaskCheckbox id={id} key={id} onSelection={handleSuccessorSelection} />
          ))}
        </Box>
      )}
    </FormModal>
  )
}

const TaskCheckbox = ({ id, onSelection }: { id: number; onSelection: (id: number) => void }) => {
  const task = useTaskListTask(id)
  return <Checkbox label={task.name} onChange={() => onSelection(id)} />
}
