import { useEffect, useRef, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'

import { Box, IconButton, Menu, MenuListItem, MenuListItemProps } from '@cutover/react-ui'
import { useToggleFilterPanel } from 'main/components/layout/layout-hooks'
import {
  FilterPanelToggleButton,
  SearchType,
  SubHeader,
  SubHeaderButton,
  SubHeaderResultsText,
  SubHeaderSearch
} from 'main/components/layout/shared/sub-header'
import { useLanguage } from 'main/services/hooks'
import {
  useActiveTasksFilter,
  useClearFilterState,
  useFilterCount,
  useHasFiltersState,
  useMyTasksFilter,
  useSearchQueryFilter,
  useTaskListCountState
} from 'main/recoil/data-access'
import { SaveFilterModal } from '../modals/save-filter-modal'
import { DisplayType, ViewNavigation } from './view-navigation'
import { ActiveRunModel, RunbookViewModel } from 'main/data-access'

export const RunbookSubHeader = () => {
  const toggleFilterPanel = useToggleFilterPanel()
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  const numFilters = useFilterCount()
  const numTasks = useTaskListCountState()
  const [showSaveViewModal, setShowSaveViewModal] = useState(false)
  const [searchFilter, setSearchFilter] = useSearchQueryFilter()
  const [isFilteringMyTasks, setIsFilteringMyTasks] = useMyTasksFilter()
  const [isFilteringActiveTasks, setIsFilteringActiveTasks] = useActiveTasksFilter()

  const setHighlightMode = RunbookViewModel.useOnAction('highlightMode:toggle')
  const isHighlightMode = RunbookViewModel.useGet('highlightMode')
  const getIsHighlightMode = RunbookViewModel.useGetCallback('highlightMode')

  const clearFilteringMyTasks = useClearFilterState('mt')
  const clearFilteringActiveTasks = useClearFilterState('at')
  const clearSearchQuery = useClearFilterState('q')
  const showSaveViewButton = useHasFiltersState()
  const run = ActiveRunModel.useGet()
  const searchRef = useRef<SearchType>(null)
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')
  const currentPage = displayMatch?.params.displayType as DisplayType

  useHotkeys('meta+h', async () => setHighlightMode(!(await getIsHighlightMode())), { preventDefault: true }, [])

  const handleSearch = (input?: string) => {
    if (input) {
      setSearchFilter(input)
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  const handleToggleMyTasks = () => {
    if (isFilteringMyTasks) {
      clearFilteringMyTasks()
    } else {
      setIsFilteringMyTasks(true)
    }
  }

  const handleToggleActiveTasks = () => {
    if (isFilteringActiveTasks) {
      clearFilteringActiveTasks()
    } else {
      setIsFilteringActiveTasks(true)
    }
  }

  useEffect(() => {
    if (searchFilter === undefined) {
      searchRef.current?.clear()
    }
  }, [searchFilter])

  const handleClose = () => {
    if (searchFilter) {
      clearSearchQuery()
    }
  }

  const menuOptions: MenuListItemProps[] = [
    {
      label: isHighlightMode ? t('filterMode.switchToDefault') : t('filterMode.switchToHighlight'),
      labelSuffix: t('filterMode.keyboardShortcut'),
      icon: isHighlightMode ? 'filter' : 'highlight-mode',
      onClick: () => setHighlightMode(!isHighlightMode)
    }
  ]

  return (
    <SubHeader.Items>
      <SubHeader.LeftItems>
        <Box width={{ min: '40px' }}>
          <FilterPanelToggleButton
            filterCount={numFilters}
            onClick={() => toggleFilterPanel()}
            onKeyUp={() => toggleFilterPanel()}
            tip={t('filterTasks')}
          />
        </Box>
        <SubHeaderSearch ref={searchRef} onSearch={handleSearch} onClose={handleClose} initialValue={searchFilter} />
        <SubHeaderButton onClick={() => handleToggleMyTasks()} active={!!isFilteringMyTasks} label={t('myTasks')} />
        {run?.mode === 'active' && (
          <SubHeaderButton
            onClick={() => handleToggleActiveTasks()}
            active={!!isFilteringActiveTasks}
            label={t('activeTasks')}
          />
        )}
        {showSaveViewButton && (
          <SubHeaderButton
            data-testid="save-filter"
            onClick={() => setShowSaveViewModal(true)}
            label={t('saveFilter')}
          />
        )}
        {numTasks !== undefined ? (
          <Box justify="center" margin={{ left: '4px' }}>
            <SubHeaderResultsText value={t('results', { amount: `${numTasks.filtered}/${numTasks.total}` })} />
          </Box>
        ) : null}
      </SubHeader.LeftItems>
      <SubHeader.RightItems>
        {currentPage !== 'react-dashboard' && <ViewNavigation />}
        <Menu
          trigger={
            <IconButton
              tertiary
              data-testid="sub-header-options-menu"
              label={t('moreOptions')}
              disableTooltip
              icon="more-vertical"
              css="z-index: 1;"
            />
          }
        >
          {menuOptions.map(item => (
            <MenuListItem
              label={item.label}
              labelSuffix={item.labelSuffix}
              suffixColor="text-disabled"
              key={item.label}
              icon={item.icon}
              onClick={item.onClick}
            />
          ))}
        </Menu>
        {isHighlightMode && (
          <IconButton
            icon="highlight-mode"
            label={t('filterMode.highlightLabel')}
            tipPlacement="top"
            onClick={() => setHighlightMode(false)}
          />
        )}
      </SubHeader.RightItems>
      {showSaveViewModal && <SaveFilterModal closeModal={() => setShowSaveViewModal(false)} />}
    </SubHeader.Items>
  )
}
