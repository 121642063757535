import { memo, ReactNode, useEffect, useState } from 'react'
import { isEqual } from 'lodash'

import { Modal } from '@cutover/react-ui'
import { usePostAppEvents } from './apps-api'
import { AppsComponentParser } from './apps-component-parser'
import { AppModalViewState } from './apps-types'
import {
  useAppsFieldValuesCallback,
  useAppsModalLoadingState,
  useResetAppsModalState,
  useSetAppsFieldValues
} from 'main/recoil/data-access'

type AppsModalProps = {
  modal: AppModalViewState
}

export const AppsModal = memo(({ modal }: AppsModalProps) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null)
  const [modalViewState, setModalViewState] = useState<AppModalViewState | null>(null)
  const resetModalState = useResetAppsModalState()
  const postAppEvents = usePostAppEvents()
  const { resourceId, appId, view, open } = modal
  const [loading, setLoading] = useAppsModalLoadingState()

  const {
    back_arrow: backArrow,
    block_dismissal: blockDismissal,
    hide_footer: hideFooter,
    content,
    button
  } = view ?? {}
  const context = `${resourceId}-${appId}`
  const getfieldValues = useAppsFieldValuesCallback()
  const setAppFieldValues = useSetAppsFieldValues()

  const handleModalActions = async (button: string) => {
    const values = await getfieldValues()
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: view ? [view] : undefined,
      state: { ...values[context], ...{ _modal_button: button } }
    }
    setLoading(true)
    postAppEvents(payload)
  }

  /**
   * Re-render the modal content only when the incoming AppModalViewState
   * from the websocket response differs from the current local view state.
   * This optimization prevents unnecessary re-renders by updating the
   * modal content only when a change is detected.
   * WARNING: this workaround is specific to app modals, do not use elsewhere.
   */
  useEffect(() => {
    if (isEqual(modal, modalViewState)) return

    setModalViewState(modal)
    const modalContent = <AppsComponentParser content={content ?? []} appId={appId} resourceId={resourceId} />
    setModalContent(modalContent)
  }, [modal])

  return (
    <>
      {view ? (
        <Modal
          onClickBack={backArrow ? () => handleModalActions('back') : undefined}
          dismissible={!blockDismissal}
          hasCancelButton={!blockDismissal}
          confirmText={button?.value}
          confirmIcon={button?.icon}
          hideFooter={hideFooter}
          loading={loading}
          onClickConfirm={() => {
            handleModalActions('confirm')
          }}
          onAfterClose={() => {
            resetModalState()
            setAppFieldValues(prevValues => {
              return { ...prevValues, [context]: {} }
            })
          }}
          open={open}
          title={view.header?.title || ''}
        >
          {content && content.length > 0 && modalContent}
        </Modal>
      ) : null}
    </>
  )
})
