import { BannerRagStatus, colors, RagStatusBanner, LegacyText as Text } from '@cutover/react-ui'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from '../../types'

export type RunbookRagStatusWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export const RunbookRagStatusWidget = ({ media, data }: RunbookRagStatusWidgetProps) => {
  const { status, status_message, status_author, status_updated_at } = data.runbook
  const streamsWithStatus = data.streams.filter(stream => stream.status !== 'off')

  // there can be hundreds of streams, limit to first 10 for email. For browser, we will
  // need to create a show-more functionality.
  const streams = media === 'email' ? streamsWithStatus.slice(0, 10) : streamsWithStatus
  const hasNoStatusesSet = status === 'off' && streamsWithStatus.length === 0
  const shouldNotRenderWidget = media !== 'email' && hasNoStatusesSet
  const shouldRenderEmptyMessage = media === 'email' && hasNoStatusesSet

  const content = (
    <>
      {status && status !== 'off' && (
        <>
          <RagStatusBanner
            type="runbook"
            message={status_message || ''}
            author={status_author}
            status={status}
            date={status_updated_at}
          />
        </>
      )}
      {streams.map(stream => {
        return stream.status === 'off' ? null : (
          <RagStatusBanner
            key={stream.id}
            type={stream.parent_id ? 'substream' : 'stream'}
            message={stream.status_message || ''}
            name={stream.name}
            author={stream.status_author_name}
            status={stream.status as BannerRagStatus}
            date={stream.status_updated_at}
          />
        )
      })}
    </>
  )

  return (
    <>
      {shouldNotRenderWidget ? null : (
        <MrdDashboardWidget title={data.name}>
          {shouldRenderEmptyMessage ? <EmptyMessage /> : content}
        </MrdDashboardWidget>
      )}
    </>
  )
}

const EmptyMessage = () => {
  // TODO: i18n support
  return <Text color={colors.primaryGrey}>No RAG Status has been set</Text>
}
