import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

import { QueryKeys } from './query-keys'
import { apiClient, ApiError } from '../api'

const DEFAULT_LIMIT = 20

const DEFAULTS = {
  highlight: true,
  order: 'name',
  sort_direction: 'asc',
  per_page: DEFAULT_LIMIT
}

// TODO
type TeamsResponse = {
  meta: {
    permissions: any
    total_results: number
  }
  teams: any[]
}

export function useAccountTeams({
  accountId,
  params = {},
  options = { enabled: true }
}: {
  accountId?: string | number
  params?: Record<string, any>
  options?: UseInfiniteQueryOptions<TeamsResponse, ApiError>
}) {
  const { q, ...restParams } = params
  const serverParams = { ...DEFAULTS, ...restParams, query: q }

  const getTeams = async (offset: number = 0) => {
    const { data } = await apiClient.get<TeamsResponse>({
      url: `accounts/${accountId}/teams`,
      params: { ...serverParams, offset }
    })

    return data
  }

  return useInfiniteQuery<TeamsResponse, ApiError>(
    [QueryKeys.Accounts, String(accountId), serverParams],
    ({ pageParam = 0 }) => getTeams(pageParam),
    {
      getNextPageParam: (lastGroup, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.total_results
        const lastOffset = allGroups.length * serverParams.per_page
        return totalResultCount > serverParams.per_page && totalResultCount > lastOffset
          ? lastOffset + serverParams.per_page
          : undefined
      },
      ...options,
      enabled: options.enabled && !!accountId
    }
  )
}
