import { SyntheticEvent, useCallback } from 'react'
import styled from 'styled-components'

import { Box, Button, Checkbox, IconButton, Text, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useFilteredTaskListIds, useTaskProgression } from 'main/recoil/data-access'
import { RunbookViewModel } from 'main/data-access'

export const TaskListHeader = ({ canCreateRootTask }: { canCreateRootTask: boolean }) => {
  const { t } = useLanguage('tasks')

  const canBulkEdit = RunbookViewModel.useCanAction('initiate:bulkEditActions')
  const canBulkProgress = RunbookViewModel.useCanAction('initiate:bulkProgressionActions')
  const modalOpen = RunbookViewModel.useOnAction('modal:open')
  const selectedIds = RunbookViewModel.useGet('selectedIds')
  const selectedIdsToggleAll = RunbookViewModel.useOnAction('selectedIds:toggleAll')

  const { onSkipTasks } = useTaskProgression()

  const filteredTaskIds = useFilteredTaskListIds()
  const toggleTaskCreate = RunbookViewModel.useOnAction('taskCreate:toggle')
  const { predecessor: fromPredecessorId } = RunbookViewModel.useGet('taskCreate')

  const disableCreateButton = fromPredecessorId === 0
  const isSelectMode = !!selectedIds.length
  const allTasksSelected = selectedIds.length === filteredTaskIds.length

  const handleChangeSelectAll = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdsToggleAll()
    },
    [selectedIdsToggleAll]
  )

  const handleClickCreateTask = () => {
    toggleTaskCreate({ predecessor: 0 })
  }

  return (
    <Box direction="row" align="center" gap="medium" height={{ min: '64px' }}>
      <Box direction="row" css="min-width: 140px;">
        <Box
          align="center"
          justify="center"
          height="40px"
          width={{ width: '40px', min: '40px' }}
          margin={{ left: '6px' }}
        >
          <Tooltip
            placement="top"
            content={isSelectMode ? t('bulkActionTooltip.deselect') : t('bulkActionTooltip.select')}
          >
            <Checkbox
              data-testid="task-select-all"
              width={16}
              disabled={filteredTaskIds.length === 0}
              checked={isSelectMode && allTasksSelected}
              indeterminate={isSelectMode && !allTasksSelected}
              onChange={handleChangeSelectAll}
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        </Box>
        {canBulkEdit || canBulkProgress ? (
          <Box direction="row" gap="xsmall">
            {canBulkProgress && (
              <IconButton
                tertiary
                label={t('bulkActionTooltip.skip')}
                data-testid="bulk-skip-task-button"
                icon="skip"
                onClick={() => onSkipTasks()}
                tipPlacement="top"
              />
            )}
            {canBulkEdit && (
              <IconButton
                tertiary
                label={t('bulkActionTooltip.delete')}
                icon="trash-o"
                tipPlacement="top"
                onClick={() => modalOpen({ type: 'tasks-delete', id: selectedIds })}
              />
            )}
            <SingleLineText data-testid="task-select-all-label">{`${selectedIds.length} ${t(
              'list.header.selected'
            )}`}</SingleLineText>
          </Box>
        ) : (
          <Text
            alignSelf="center"
            data-testid="task-select-all-label"
            color={filteredTaskIds.length === 0 ? 'text-disabled' : 'text-light'}
          >
            {t('list.header.checkboxLabel')}
          </Text>
        )}
      </Box>

      {canCreateRootTask && !isSelectMode && (
        <Box>
          <Button
            data-testid="create-task-no-predecessors-button"
            tertiary
            disableTooltip
            disabled={disableCreateButton}
            icon="add"
            onClick={handleClickCreateTask}
            label={t('createTask')}
          />
        </Box>
      )}
    </Box>
  )
}

const SingleLineText = styled(Text).attrs(() => ({
  alignSelf: 'center',
  color: 'text-light'
}))`
  overflow: hidden;
  white-space: nowrap;
`
