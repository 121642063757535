import { useCallback } from 'react'
import { useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { keyBy } from 'lodash'

import { runbookVersionResponseState_INTERNAL } from '../../runbook/models'
import {
  RunbookPersonCreateResponse,
  RunbookPersonDeleteResponse,
  RunbookResponse
} from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse, RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { RoleType, RunbookTeam } from 'main/services/queries/types'

export const useProcessRunbookPersonResponse = () => {
  const processRunbookPersonCreateResponse = useProcessRunbookPersonCreateResponse()
  const processRunbookPersonDeleteResponse = useProcessRunbookPersonDeleteResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookPersonCreateResponse(response as RunbookPersonCreateResponse)
          break
        case 'delete':
          processRunbookPersonDeleteResponse(response as RunbookPersonDeleteResponse)
        default:
          return
      }
    },
    [processRunbookPersonCreateResponse, processRunbookPersonDeleteResponse]
  )
}

export const useProcessRunbookPersonCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookPersonCreateResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const addedUsers = response.users
        const addedRunbookTeams = response.runbook_teams

        const runbookUserLookup = keyBy(draftRunbookVersionResponse.meta.users, 'id')
        const runbookTeamsLookup = keyBy(draftRunbookVersionResponse.meta.runbook_teams, 'id')

        addUsersToMeta({ addedUsers, runbookUserLookup, draftRunbookVersionResponse })
        addRunbookTeamsToMeta({ addedRunbookTeams, runbookUserLookup, runbookTeamsLookup, draftRunbookVersionResponse })
      })
    )
  })
}

const addUsersToMeta = ({
  addedUsers,
  runbookUserLookup,
  draftRunbookVersionResponse
}: {
  addedUsers: RunbookVersionUser[]
  runbookUserLookup: Record<RunbookVersionUser['id'], RunbookVersionUser>
  draftRunbookVersionResponse: GetRunbookVersionResponse
}) => {
  addedUsers?.forEach(user => {
    if (!runbookUserLookup[user.id]) {
      draftRunbookVersionResponse.meta.users.push(user)
    }
  })
}

const addRunbookTeamsToMeta = ({
  addedRunbookTeams,
  runbookUserLookup,
  runbookTeamsLookup,
  draftRunbookVersionResponse
}: {
  addedRunbookTeams: (RunbookTeam & { role_types?: RoleType[] })[]
  runbookTeamsLookup: Record<number, RunbookTeam>
  runbookUserLookup: Record<RunbookVersionUser['id'], RunbookVersionUser>
  draftRunbookVersionResponse: GetRunbookVersionResponse
}) => {
  addedRunbookTeams?.forEach(team => {
    if (!runbookTeamsLookup[team.id]) {
      const { role_types, user_ids, ...restTeam } = team
      draftRunbookVersionResponse.meta.runbook_teams.push({
        ...restTeam,
        user_ids: user_ids || []
      })

      if (role_types && role_types[0].users.length > 0) {
        addUsersToMeta({ addedUsers: role_types[0].users, runbookUserLookup, draftRunbookVersionResponse })
      }
    }
  })
}

export const useProcessRunbookPersonDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookPersonDeleteResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const { removed_user_ids } = response.meta
        removed_user_ids.forEach(id => {
          const index = draftRunbookVersionResponse.meta.users.findIndex(user => user.id === id)
          draftRunbookVersionResponse.meta.users.splice(index, 1)
        })
      })
    )
  })
}
