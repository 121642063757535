import { Box, colors, Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export type TeamUnlinkModalProps = {
  isOpen: boolean
  setIsUnlinking: (val: boolean) => void
  unlinkTeam: () => void
}

export function TeamUnlinkModal({ isOpen, unlinkTeam, setIsUnlinking }: TeamUnlinkModalProps) {
  const { t } = useLanguage()

  const handleSave = () => {
    unlinkTeam()
    setIsUnlinking(false)
  }

  const setIsUnlinkingFalse = () => {
    setIsUnlinking(false)
  }

  return (
    <Modal
      title={t('runbook:peoplePanel:teams:unlinkTitle')}
      open={isOpen}
      onClose={setIsUnlinkingFalse}
      onClickConfirm={handleSave}
      confirmText={t('common:continueButton')}
      confirmIcon="arrow-forward"
      zIndex={101}
    >
      <Box>
        <Text color={colors.text}>{t('runbook:peoplePanel:teams:unlinkDescription')}</Text>
      </Box>
    </Modal>
  )
}
